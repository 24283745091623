//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import project from '~/static/config/project.json'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    page: {
      type: Object,
      default () {
        return {
          url: '',
          title: '',
          clipborad: false
        }
      }
    },
    extend: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      facebook: project?.rrss?.facebook?.channel && {
        channel: project.rrss.facebook.channel,
        img: {
          src: require('@/assets/img/btn-fb.svg'),
          alt: 'Facebook Icon'
        }
      },
      twitter: project?.rrss?.twitter?.channel && {
        channel: project.rrss.facebook.channel,
        img: {
          src: require('@/assets/img/btn-tw.svg'),
          alt: 'Twitter Icon'
        }
      },
      whatsapp: project?.rrss?.whatsapp && {
        img: {
          src: require('@/assets/img/btn-wp.svg'),
          alt: 'Whatsapp Icon'
        }
      },
      youtube: project?.rrss?.youtube?.channel && {
        channel: project.rrss.facebook.channel,
        img: {
          src: require('@/assets/img/btn-yt.svg'),
          alt: 'Youtube Icon'
        }
      },
      clipboard: false
    }
  },
  computed: {
    urlPath () {
      let urlPath = this.page.url || this.$route.path
      if (!urlPath.match(/^https?:\/\//)) {
        urlPath = project.domains.www.protocol + '://' + project.domains.www.domain + urlPath
      }
      return urlPath
    }

  },
  mounted () {
    this.clipboard = this.page.clipboard && navigator && navigator.clipboard
  },
  methods: {
    create_social_url (network) {
      let url = ''
      switch (network) {
        case 'facebook':
          url = 'http://www.facebook.com/sharer/sharer.php?u=' + this.urlPath + '&t=' + this.page.title
          break
        case 'twitter':
          url = 'http://www.twitter.com/intent/tweet?url=' + this.urlPath + '&via=' + project.rrss.twitter.channel + '&text=' + this.page.title
          break
        case 'whatsapp':
          url = 'whatsapp://send?text=' + this.urlPath
          break
      }
      return url
    },
    create_follow_url (network) {
      let url = ''
      switch (network) {
        case 'facebook':
          url = 'http://www.facebook.com/' + project.rrss.facebook.channel
          break
        case 'youtube':
          url = 'http://www.youtube.com/channel/' + project.rrss.youtube.channel + '?sub_confirmation=1'
          break
      }
      return url
    },
    copy_text () {
      this.$refs.copytoclipboard.$emit('open')
      if (this.clipboard) {
        navigator.clipboard.writeText(this.urlPath)
      }
      setTimeout(() => {
        this.$refs.copytoclipboard.$emit('close')
      }, 2000)
    }
  }
}
