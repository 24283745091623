//
//
//
//
//

import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  name: 'NuxtError',
  layout: 'void', // optional
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  async asyncData ({ app, store, route, error, $axios, $config }) {
    // Zones & Heritages

    const ax = new AxiApi($config.api)
    const zoneUrl = $config.static + '/json/pages/404.json'

    let landingData

    try {
      landingData = await $axios.get(zoneUrl)
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(landingData.data, store)

    const head = {
      title: this.$t('register.successfully_registered')
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'not_found_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      head
    }
  },
  head () {
    customPageView.apply(this, [this.head.title])
    return this.head
  }
}
