//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      querySearch: ''
    }
  },
  mounted () {
    this.querySearch = (this.text !== '') ? this.text : ''
  },
  methods: {
    textSearch () {
      const qryStrObj = this.$route.query
      if (this.querySearch !== '') {
        qryStrObj.q = this.querySearch
        window.location.href = this.$router.resolve({ path: this.$route.path.replace(this.$route.params.pathMatch, ''), query: qryStrObj }).href
      } else {
        delete qryStrObj.q
        window.location.href = this.$router.resolve({ path: this.$route.path.replace(this.$route.params.pathMatch, ''), query: qryStrObj }).href
      }
    },
    deleteTextSearch () {
      const qryStrObj = this.$route.query
      delete qryStrObj.q
      return this.$router.resolve({ path: this.$route.path.replace(this.$route.params.pathMatch, ''), query: qryStrObj }).href
    }
  }
}
