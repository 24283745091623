//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import axios from 'axios'
import * as formFunctions from '~/mixins/form.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  layout: 'clean.close',
  data () {
    const head = {
      title: 'Runnea - Auth Remember',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    return {
      formValidated: false,
      rememberData: {
        email: {
          value: '',
          validate: () => this.isEmail()
        }
      },
      feedback: {
        valid: '',
        invalid: ''
      },
      authId: this.$route.query._ai,
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.rememberData[field]?.validate || this.rememberData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.rememberData)
    },
    isRequired (field, value = '') {
      return (this.rememberData[field].value !== value)
    },
    isEmail () {
      return this.isRequired('email') && formFunctions.isValidEmail(this.rememberData.email.value)
    },
    async sendPassword () {
      this.formValidated = true
      if (this.validateForm()) {
        const bodyFormData = new FormData()
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('email', this.rememberData.email.value)
        bodyFormData.append('version', 2)
        try {
          await axios.post(this.$config.api + '/app/v1/users/password', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            this.feedback.valid = this.$t('register.remember_ok', {
              email: this.rememberData.email.value
            })
          })
        } catch (error) {
          this.feedback.invalid = this.$t('register.not_exist_email')
        }
      }
    }
  }
}
