import Vue from 'vue';

import {
  AvatarPlugin,
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  EmbedPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormPlugin,
  ImagePlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  SidebarPlugin,
  ProgressPlugin,
  TablePlugin,
  TooltipPlugin
} from 'bootstrap-vue';

Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(CardPlugin);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(EmbedPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(ProgressPlugin);
Vue.use(TablePlugin);
Vue.use(TooltipPlugin);
