//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ params, route, app, store, $axios, $md5, $config, error }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    try {
      const query = $config.static + '/json/forum' + route.path.replace('html', 'json')
      const code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }
      const talk = store.state.lists[code]

      const zones = await $axios.get(
        $config.static + '/json/zones/inquiry/zones.json'
      )
      await ax.createComponentData(zones.data, store)

      const talkComments = JSON.parse(JSON.stringify(talk.comments))

      talkComments.comments.forEach((comment) => {
        if (comment.wysiwyg && (comment.wysiwyg.length > 0)) {
          zones.data.zones['Comment_' + comment.content_id] = JSON.parse(JSON.stringify(comment.wysiwyg))
        }
      })

      await ax.createComponentData(zones.data, store)
      const zonesContent = ax.get()

      talkComments.comments.forEach((comment, iComment) => {
        if (zonesContent?.GlobalData?.zones['Comment_' + comment.content_id] && (zonesContent.GlobalData.zones['Comment_' + comment.content_id].length > 0)) {
          talkComments.comments[iComment].wysiwyg = zonesContent.GlobalData.zones['Comment_' + comment.content_id]
        }
      })

      // Eliminamos posibles emptys
      const products = talk.products.filter(e => Object.keys(e).length > 0)

      const header = {
        h1: talk.title
      }
      const head = {
        title: `${talk.title} | ${app.i18n.t('talk_page.consultant')} Runnea`,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: `${talk.title} | ${app.i18n.t('talk_page.consultant')} Runnea`
          }
        ]
      }

      if (Object.keys(route.query).length > 0) {
        head.meta.push(
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex, follow'
          }
        )
      }

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'talk_page', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
        })
      }

      return {
        talk,
        comments: talkComments,
        header,
        head,
        zones: zonesContent,
        products
      }
    } catch (err) {
      console.log(err.message)
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    const head = {
      title: 'Runnea - Talk Page'
    }
    return {
      plus: { alt: 'close', src: require('@/assets/img/icons/icon-plus.svg'), width: 11, heigth: 11 },
      minus: { alt: 'open', src: require('@/assets/img/icons/icon-minus-primary.svg'), width: 11, heigth: 11 },
      avatar: this.talk?.user?.avatar ?? '',
      name: this.talk?.user?.name ?? '',
      time: this.talk?.time ?? '',
      question: this.talk?.text ?? '',
      description: this.talk?.text ?? '',
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
