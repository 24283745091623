export default (context, inject) => {
  const strTr = (str, from, to) => {
    let out = ''
    for (let i = 0, m = str.length; i < m; i++) {
      const p = from.indexOf(str.charAt(i))
      if (p >= 0) {
        out = out + to.charAt(p)
      } else {
        out += str.charAt(i)
      }
    }
    return out
  }

  const pregQuote = (str, delimiter) => {
    return (str + '').replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'), '\\$&')
  }

  const txt2system = (text, letras_permitidas = ['.', '_'], separacion = '-') => {
    text = text.trim().toLowerCase()
    text = (text + '').replace(new RegExp('[^a-z0-9���������������� ' + pregQuote(letras_permitidas.join('') + separacion) + ']', 'g'), '')
    text = strTr(text, '����������������', 'aeiouaeiouaeioun')
    text = (text + '').replace(/ /g, separacion)
    return text
  }

  // Inject $txt2system(text) in Vue, context and store.
  inject('txt2system', txt2system)
  // For Nuxt <= 2.12, also add ??
  context.$txt2system = txt2system
}
