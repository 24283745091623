//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { openAuthRequired } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'default.amp' : 'default'
  },
  async asyncData ({ params, route, store, app, error, $axios, $config, $md5, $auth }) {
    function setArticleLink (path) {
      const pathMatches1 = path.match(/^\/json\/articles\/(.+?)\.index\.json$/)
      const pathMatches2 = path.match(/^\/json\/articles\/(.+?)index\.json$/)
      let urlpath = false
      if (pathMatches1) {
        urlpath = `/${projectData.contents.articles.all.paths.home}/${pathMatches1[1]}.html`
      } else if (pathMatches2) {
        urlpath = `/${projectData.contents.articles.all.paths.home}/${pathMatches2[1]}`
      }
      return urlpath
    }

    function setArticleAMPLink (path) {
      let urlpath = ''
      if (path.match(/^\/json\/galleries\/(.+?).json$/)) {
        urlpath = this.setGalleryLink(path)
      } else {
        const pathMatches = path.match(/^\/json\/articles\/(.+?)index.json$/)
        if (typeof pathMatches !== 'undefined') {
          urlpath = `/${projectData.contents.articles.all.paths.home}/${pathMatches[1]}`
        } else {
          urlpath = `/${projectData.contents.articles.all.paths.index || projectData.contents.articles.all.paths.home}/`
        }
      }
      return `${urlpath}index.amp.html`
    }

    function unescapeHtml (safe) {
      return safe
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&#8220;/g, '"')
        .replace(/&#8221;/g, '"')
    }

    const urlPath = params.pathMatch.replace('.html', '.')

    // Zones & Heritages
    const ax = new AxiApi($config.api)

    const call = `${$config.static}/json/articles/${urlPath}zones.json`
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      zones = {}
    }

    await ax.createComponentData(zones.data, store)

    // Get JSON data
    let query = `${$config.static}/json/articles/${urlPath}index.json`
    let code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', query)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const article = store.state.lists[code]
    const related = article.related.filter((e) => Object.keys(e).length > 0)

    query = `${$config.static}/json/authors/${article.author.id}.json`
    code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const author = store.state.lists[code]

    // Get Sections Data & Set Section
    const sectQry = $config.static + '/json/articles/sections.json'
    const sectCode = $md5(sectQry)
    if (
      typeof store.state.lists[sectCode] === 'undefined' ||
      Object.keys(store.state.lists[sectCode]).length === 0
    ) {
      await store.dispatch('loadLists', sectQry)
    }
    const sections = store.state.lists[sectCode]
    let section = false
    const categoria = article.category.id || params.pathMatch.split('/')[0]
    if (categoria) {
      section = sections.filter((s) => s.id === categoria)[0]
    }
    let selected = false
    if (section) {
      selected = `/${projectData.contents.articles.all.paths.home}/${projectData.contents.articles.all.paths.categories}/${section.id}/`
    }

    let menuMagazine = []
    menuMagazine = sections.filter((s) => s.isInMenu === '1')
    menuMagazine.sort((a, b) =>
      a.menuPos > b.menuPos ? 1 : b.menuPos > a.menuPos ? -1 : 0
    )

    // BreadCrumb
    const breadcrumb = []
    breadcrumb.push({
      text: `${app.i18n.t('article_page.magazine')}`,
      href: `/${projectData.contents.articles.all.paths.index || projectData.contents.articles.all.paths.home}/`
    })
    if (article.category?.name !== '' && article.category?.id !== '') {
      breadcrumb.push({
        text: `${article.category.name}`,
        href: `/${projectData.contents.articles.all.paths.home}/${projectData.contents.articles.all.paths.categories}/${article.category.id}/`
      })
    }
    if (article.title) {
      breadcrumb.push({
        text: unescapeHtml(article.title)
      })
    }

    // Structured Data
    const structuredData = {
      '@context': 'http://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': $config.domain + route.fullPath
      },
      headline: `${article.title}`,
      datePublished: `${article.published}`,
      publisher: {
        '@type': 'Organization',
        name: 'Runnea',
        logo: {
          '@type': 'ImageObject',
          url: `https://static.runnea.com/images/202406/logo-header-runnea.3fdb16f.svg`,
          width: '273',
          height: '66'
        }
      },
      author: {
        '@type': 'Person',
        name: `${author.name}`
      },
      description: `${article.entrance}`,
      articleSection: `${article.category.name}`,
      dateModified: `${article.modified}`
    }

    if (article.photo.body.large?.src) {
      structuredData.image = {
        '@type': 'ImageObject',
        url: `${article.photo.body.large.src}`,
        width: `${article.photo.body.large.width}`,
        height: `${article.photo.body.large.height}`
      }
    }

    const structuredDataBreadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: []
    }
    if (breadcrumb.length > 0) {
      breadcrumb.forEach(function (element, index) {
        if (element.active) {
          structuredDataBreadcrumbList.itemListElement.push({
            '@type': 'ListItem',
            position: index + 1,
            name: element.text
          })
        } else {
          structuredDataBreadcrumbList.itemListElement.push({
            '@type': 'ListItem',
            position: index + 1,
            name: element.text,
            item: `${$config.domain}${element.href}`
          })
        }
      })
    }

    // Metas & Head
    let ampPath = route.path
    if (app.$isAMP) {
      ampPath = route.path.replace('index.amp.html', '')
      ampPath = setArticleLink(article.path)
    }
    const head = {
      title:
        article.metas.title !== ''
          ? `${unescapeHtml(article.metas.title)}`
          : `${unescapeHtml(article.title)}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content:
            article.metas.title !== ''
              ? `${unescapeHtml(article.metas.title)}`
              : `${unescapeHtml(article.title)}`
        },
        {
          hid: 'description',
          name: 'description',
          content:
            article.metas.description !== ''
              ? `${article.metas.description}`
              : `${article.entrance}`
        },
        {
          property: 'og:url',
          content: `${$config.domain}${ampPath}`
        },
        {
          property: 'og:title',
          content:
            article.metas.title !== ''
              ? `${unescapeHtml(article.metas.title)}`
              : `${unescapeHtml(article.title)}`
        },
        {
          property: 'og:description',
          content:
            article.metas.description !== ''
              ? `${article.metas.description}`
              : `${article.entrance}`
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: `${$config.locale}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${ampPath}`
        }
      ],
      script: [
        { type: 'application/ld+json', json: structuredData },
        { type: 'application/ld+json', json: structuredDataBreadcrumbList }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, follow'
      })
    }

    if (article.structured_data && article.structured_data.length > 0) {
      article.structured_data.forEach((element) => {
        head.script.push({ type: 'application/ld+json', json: element })
      })
    }

    if (article?.photo?.body?.large?.src) {
      head.meta.push({
        property: 'og:image',
        content: `${article.photo.body.large.src}`
      })
    }

    if (!app.$isAMP && projectData.amp) {
      head.link.push({
        hid: 'amphtml',
        rel: 'amphtml',
        href:
          article.metas.amp !== ''
            ? `${$config.domain}${setArticleAMPLink(article.metas.amp)}`
            : `${$config.domain}${route.fullPath}index.amp.html`
      })
    }

    if (article?.versions?.length > 0) {
      article.versions.forEach((version) => {
        if (version.lang === 'es') {
          head.link.push({
            rel: 'alternate',
            hreflang: version.lang,
            href: app.$isAMP
              ? version.amp
                ? version.amp
                : version.url + 'index.amp.html'
              : version.url
          })
          head.link.push({
            rel: 'alternate',
            hreflang: 'x-default',
            href: app.$isAMP
              ? version.amp
                ? version.amp
                : version.url + 'index.amp.html'
              : version.url
          })
        } else {
          head.link.push({
            rel: 'alternate',
            hreflang: version.lang,
            href: app.$isAMP
              ? version.amp
                ? version.amp
                : version.url + 'index.amp.html'
              : version.url
          })
        }
      })
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'article_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      article,
      author,
      zones: zonesContent,
      head,
      breadcrumb,
      related,
      section,
      sections,
      selected,
      menuMagazine
    }
  },
  data () {
    return {
      text: '',
      articleImage: { width: 830, height: 396, class: 'ArticleImage' },
      authId: openAuthRequired.apply(this, [null, null, 'component', 'premiumContent']),
      projectData
    }
  },
  head () {
    return this.head
  },
  beforeMount () {
    if ((this.article.premium === '1') && this.zones.GlobalData?.zones?.Text && !this.$auth.loggedIn) {
      this.zones.GlobalData.zones.Text.splice(3)
    }
  },
  mounted () {
    customPageView.apply(this, [this.head.title])

    const hash = this.$route?.hash ?? ''
    if (hash) {
      const container = document.querySelector(hash)
      if (container) {
        this.activeSection = hash.replace('#', '')
        container.scrollIntoView()
      }
    }
  }
}
