
export default (options, texts) => {
  return Object.assign({}, {
    revision: 0,
    current_lang: 'rn',
    cookie_name: 'cc_runnea',
    cookie_expiration: 365,
    force_consent: false,
    gui_options: {
      consent_modal: {
        layout: 'bar',
        position: 'bottom center',
        transition: 'slide'
      },
      settings_modal: {
        layout: 'box',
        transition: 'slide'
      }
    },
    languages: {
      rn: {
        consent_modal: {
          title: texts.consent_modal.title,
          description: texts.consent_modal.description,
          primary_btn: {
            text: texts.consent_modal.primary_btn,
            role: 'accept_all'
          },
          secondary_btn_1: {
            text: texts.consent_modal.secondary_btn_1,
            role: 'settings'
          },
          secondary_btn_2: {
            text: texts.consent_modal.secondary_btn_2,
            role: 'accept_necessary'
          }
        },
        settings_modal: {
          title: texts.settings_modal.title,
          save_settings_btn: texts.settings_modal.save_settings_btn,
          accept_all_btn: texts.settings_modal.accept_all_btn,
          reject_all_btn: texts.settings_modal.reject_all_btn,
          close_btn_label: texts.settings_modal.close_btn_label,
          cookie_table_headers: [
            { col1: texts.settings_modal.cookie_table_headers.col1 },
            { col2: texts.settings_modal.cookie_table_headers.col2 },
            { col3: texts.settings_modal.cookie_table_headers.col3 },
            { col4: texts.settings_modal.cookie_table_headers.col4 }
          ],
          blocks: [
            {
              title: texts.settings_modal.blocks_1.title,
              description: texts.settings_modal.blocks_1.description
            }, {
              title: texts.settings_modal.blocks_2.title,
              description: texts.settings_modal.blocks_2.description,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              },
              cookie_table: [
                {
                  col1: 'hash',
                  col2: 'runnea.com',
                  col3: texts.settings_modal.cookies.hash.expiration,
                  col4: texts.settings_modal.cookies.hash.description
                },
                {
                  col1: 'auth.strategy',
                  col2: 'runnea.com',
                  col3: texts.settings_modal.cookies.auth_strategy.expiration,
                  col4: texts.settings_modal.cookies.auth_strategy.description
                },
                {
                  col1: 'cc_runnea',
                  col2: 'runnea.com',
                  col3: texts.settings_modal.cookies.cc_runnea.expiration,
                  col4: texts.settings_modal.cookies.cc_runnea.description
                }
              ]
            }, {
              title: texts.settings_modal.blocks_3.title,
              description: texts.settings_modal.blocks_3.description,
              toggle: {
                value: 'functional',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^brander_',
                  col2: 'runnea.com',
                  col3: texts.settings_modal.cookies.brander.expiration,
                  col4: texts.settings_modal.cookies.brander.description,
                  is_regex: true
                }
              ]
            }, {
              title: texts.settings_modal.blocks_4.title,
              description: texts.settings_modal.blocks_4.description,
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: texts.settings_modal.cookies.ga.expiration,
                  col4: texts.settings_modal.cookies.ga.description,
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: texts.settings_modal.cookies.gid.expiration,
                  col4: texts.settings_modal.cookies.gid.description
                }
              ]
            }, {
              title: texts.settings_modal.blocks_5.title,
              description: texts.settings_modal.blocks_5.description,
              toggle: {
                value: 'marketing',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '__gads',
                  col2: 'google.com',
                  col3: texts.settings_modal.cookies.gads.expiration,
                  col4: texts.settings_modal.cookies.gads.description
                },
                {
                  col1: '_fbp',
                  col2: 'facebook.com',
                  col3: texts.settings_modal.cookies.fbp.expiration,
                  col4: texts.settings_modal.cookies.fbp.description
                }
              ]
            }
          ]
        }
      }
    }
  }, options || {})
}
