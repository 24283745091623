const middleware = {}

middleware['ArticleFinder'] = require('../middleware/ArticleFinder.js')
middleware['ArticleFinder'] = middleware['ArticleFinder'].default || middleware['ArticleFinder']

middleware['Auth'] = require('../middleware/Auth.js')
middleware['Auth'] = middleware['Auth'].default || middleware['Auth']

middleware['Brands'] = require('../middleware/Brands.js')
middleware['Brands'] = middleware['Brands'].default || middleware['Brands']

middleware['Filters'] = require('../middleware/Filters.js')
middleware['Filters'] = middleware['Filters'].default || middleware['Filters']

middleware['Origin'] = require('../middleware/Origin.js')
middleware['Origin'] = middleware['Origin'].default || middleware['Origin']

middleware['ProductFinder'] = require('../middleware/ProductFinder.js')
middleware['ProductFinder'] = middleware['ProductFinder'].default || middleware['ProductFinder']

middleware['RaceFinder'] = require('../middleware/RaceFinder.js')
middleware['RaceFinder'] = middleware['RaceFinder'].default || middleware['RaceFinder']

middleware['Stores'] = require('../middleware/Stores.js')
middleware['Stores'] = middleware['Stores'].default || middleware['Stores']

export default middleware
