//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
export default {
  data () {
    return {
      show: false,
      data: {},
      config: {}
    }
  },
  watch: {
    '$route.path' () {
      this.loadBanner()
    }
  },
  mounted () {
    this.loadBanner()
  },
  methods: {
    async loadBanner () {
      let layerId = 0
      const expires = new Date()
      expires.setMonth(expires.getMonth() + 12)
      try {
        await axios.get(this.$config.static + '/json/layers/index.json').then((layers) => {
          for (let index = 0; index < layers.data.length; index++) {
            const element = layers.data[index]
            for (let i = 0; i < element.rules.length; i++) {
              const rule = element.rules[i]
              if (rule.if === 'no' && this.$route.path.includes(rule.route)) {
                this.show = false
                return 2
              } else if (rule.if === 'si' && rule.route === this.$route.path) {
                this.config = element
                layerId = element.id
                return 2
              }
            }
          }
        })
      } catch (error) {
        this.show = false
      }
      if (layerId > 0) {
        try {
          await axios.get(this.$config.static + '/json/layers/' + layerId + '.json').then((data) => {
            this.data = data.data
            const brander = (typeof this.$cookiz.get('brander_' + layerId) !== 'undefined') ? parseInt(this.$cookiz.get('brander_' + layerId)) : 0
            const branderTotal = (typeof this.$cookiz.get('brander_total_' + layerId) !== 'undefined') ? parseInt(this.$cookiz.get('brander_total_' + layerId)) : 0
            const ccCookie = this.$cookiz.get('cc_runnea')
            if (((this.config.frecuency.howMatch === '0') || (brander < parseInt(this.config.frecuency.howMatch))) && (branderTotal >= parseInt(this.config.moment.entries))) {
              setTimeout(() => {
                const cookieOptions = { path: '/' }
                if (this.config.frecuency.interval !== '0') {
                  this.config.frecuency.expires = parseInt(this.config.frecuency.interval)
                }
                if (ccCookie && ccCookie.categories.includes('functional')) {
                  this.$cookiz.set('brander_' + layerId, brander + 1, cookieOptions)
                }
                this.show = true
              }, this.config.moment.seconds * 1000)
            }
            if (ccCookie && ccCookie.categories.includes('functional')) {
              this.$cookiz.set('brander_total_' + layerId, branderTotal + 1, { path: '/' })
            }
          })
        } catch (error) {
          this.show = false
        }
      }
    }
  }
}
