//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as formFunctions from '~/mixins/form.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  layout: 'clean.close',
  async asyncData ({ router, params }) {
    if (!params.token) {
      router.push('/auth/login')
    }

    return {
      token: params.token || false
    }
  },
  data () {
    const head = {
      title: 'Runnea - Auth Password',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    return {
      formValidated: false,
      showPass1: false,
      showPass2: false,
      passwordData: {
        pass1: {
          value: '',
          validate: () => this.isPassword()
        },
        pass2: {
          value: '',
          validate: () => this.isSamePassword()
        }
      },
      feedback: {
        valid: '',
        invalid: ''
      },
      token: false,
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.passwordData[field]?.validate || this.passwordData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.passwordData)
    },
    isRequired (field, value = '') {
      return (this.passwordData[field].value !== value)
    },
    isPassword () {
      return this.isRequired('pass1') && formFunctions.isValidPassword(this.passwordData.pass1.value)
    },
    isSamePassword () {
      return this.isRequired('pass2') && (this.passwordData.pass1.value === this.passwordData.pass2.value)
    },
    newPassword () {
      this.formValidated = true
      if (this.validateForm()) {
        try {
          this.$axios.put(this.$config.api + '/app/v1/users/password', `_p=${this.$config.id}&password=${this.passwordData.pass1.value}&password2=${this.passwordData.pass2.value}&token=${this.token}`, {
            headers: {
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            this.feedback.valid = this.$t('reset_password.successfully_update')
            setTimeout(() => {
              this.$router.push('/auth/login')
            }, 3000)
          }).catch(() => {
            this.feedback.invalid = this.$t('reset_password.unknown_error')
          })
        } catch (error) {
          this.feedback.invalid = this.$t('reset_password.unknown_error')
        }
      }
    }
  }
}
