//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    inquirie: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    brand: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    photo: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    name: {
      type: String,
      default: ''
    },
    avatarProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      text: this.inquirie?.title ?? this.inquirie.text,
      avatar: {
        src: this.avatarProp?.src ?? require('@/assets/img/icons/icon-avatar.svg'),
        width: this.avatarProp?.width ?? 50,
        height: this.avatarProp?.height ?? 50,
        alt: this.name ?? 'avatar opinion'
      }
    }
  }
}
