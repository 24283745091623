//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ route, store, app, $axios, $config }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/brands/zones.json'
    const zones = await $axios.get(call)
    await ax.createComponentData(zones.data, store)

    // Get data from Store
    let filteredBrands = []
    filteredBrands = store.state.brands.filter(brand => brand.products.length > 0)

    // Structured Data
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: [
      ],
      itemListOrder: 'https://schema.org/ItemListUnordered',
      name: app.i18n.t('brand_list.marks') ? app.i18n.t('brand_list.marks') : ''

    }
    filteredBrands.forEach((brand, i) => {
      structuredData.itemListElement.push({
        '@type': 'ListItem',
        position: (i + 1),
        url: `https://www.runnea.com/${projectData.contents.brands.all.paths.home}/${brand.id}/`,
        image: `${brand.logo.small}`,
        '@id': `https://www.runnea.com/${projectData.contents.brands.all.paths.home}/${brand.id}/`,
        name: `${brand.name}`
      })
    })

    // Metas & Head
    const head = {
      title: app.i18n.t('brand_list.title') ? app.i18n.t('brand_list.title') : '',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: app.i18n.t('brand_list.title') ? app.i18n.t('brand_list.title') : ''
        },
        {
          hid: 'description',
          name: 'description',
          content: app.i18n.t('brand_list.description') ? app.i18n.t('brand_list.description') : ''
        }
      ],
      script: [
        { type: 'application/ld+json', json: structuredData }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'brand_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      brands: filteredBrands,
      zones: zonesContent,
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
