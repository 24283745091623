import { countryData } from '~/lib/countries'

export const state = () => ({
  countryProject: null
})

export const mutations = {
  SETCOUNTRY (state, project) {
    state.countryProject = project || (this.$cookiz.get('countryProject') && parseInt(this.$cookiz.get('countryProject')))
  }
}

export const actions = {
  setCountry ({ commit }, project) {
    project = countryData(project) && project
    if (project) {
      this.$cookiz.set('countryProject', project, { path: '/' })
    }
    commit('SETCOUNTRY', project)
  }
}
