//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import project from '~/static/config/project.json'
export default {
  props: {
    typeId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    brandsLength () {
      return this.$store.state.brands.length
    },
    brands () {
      let filteredBrands = []
      filteredBrands = this.$store.state.brands.filter(brand => brand.products.length > 0)
      if (this.typeId > 0) {
        filteredBrands = filteredBrands.filter(
          (brand) => {
            let found = false
            brand.products.forEach((prodT) => {
              if (+(prodT.products_type) === this.typeId) {
                found = true
              }
            })
            return found
          }
        )
      }
      return filteredBrands
    }
  },
  methods: {
    getBrandUrl (brand) {
      const prodId = this.typeId || 1
      const prodTypeConfig = Object.values(project.contents.product_types).find(p => Number(p.id) === prodId)
      return (prodTypeConfig && `/${prodTypeConfig.paths.home}/${prodTypeConfig.paths.products || project.contents.product_types.products.paths.products}/${brand.id}/`) || ''
    }
  }
}
