//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
export default {
  components: {
    LazyHydrate,
    AppWysiwyg: () => import('~/components/AppWysiwyg.vue'),
    InquiryModal: () => import('~/components/InquiryModal.vue'),
    MagazineModule_A1: () => import('~/components/MagazineModule_A1.vue'),
    MagazineModule_A1_B3: () => import('~/components/MagazineModule_A1_B3.vue'),
    MagazineModule_A4: () => import('~/components/MagazineModule_A4.vue'),
    MagazineModule_A6: () => import('~/components/MagazineModule_A6.vue'),
    MagazineModule_A2_B2_C5: () => import('~/components/MagazineModule_A2_B2_C5.vue'),
    MagazineModule_A2_BC4: () => import('~/components/MagazineModule_A2_BC4.vue'),
    MagazineModule_A2_BC1_ABC3: () => import('~/components/MagazineModule_A2_BC1_ABC3.vue'),
    MagazineModule_A1_B1_AB3: () => import('~/components/MagazineModule_A1_B1_AB3.vue'),
    MagazineModuleVideos: () => import('~/components/MagazineModuleVideos.vue'),
    ModuleAdvertisingProducts: () => import('~/components/ModuleAdvertisingProducts.vue'),
    ModuleAlert: () => import('~/components/ModuleAlert.vue'),
    ModuleArticle: () => import('~/components/ModuleArticle.vue'),
    ModuleBanner: () => import('~/components/ModuleBanner.vue'),
    ModuleCloudTags: () => import('~/components/ModuleCloudTags.vue'),
    ModuleFeatured: () => import('~/components/ModuleFeatured.vue'),
    ModuleFinder: () => import('~/components/ModuleFinder.vue'),
    ModuleForm: () => import('~/components/ModuleForm.vue'),
    ModuleGpt: () => import('~/components/ModuleGpt.vue'),
    ModuleHeader: () => import('~/components/ModuleHeader.vue'),
    ModuleIconList: () => import('~/components/ModuleIconList.vue'),
    ModuleImageTitle: () => import('~/components/ModuleImageTitle.vue'),
    ModuleInfluencers: () => import('~/components/ModuleInfluencers.vue'),
    ModuleLinkList: () => import('~/components/ModuleLinkList.vue'),
    ModuleLinks: () => import('~/components/ModuleLinks.vue'),
    ModuleMenu: () => import('~/components/ModuleMenu.vue'),
    ModuleNewsLetters: () => import('~/components/ModuleNewsLetters.vue'),
    ModuleOutletLinks: () => import('~/components/ModuleOutletLinks.vue'),
    ModulePopup: () => import('~/components/ModulePopup.vue'),
    ModulePrices: () => import('~/components/ModulePrices.vue'),
    ModuleProductComparisons: () => import('~/components/ModuleProductComparisons.vue'),
    ModuleProductFeatured: () => import('~/components/ModuleProductFeatured.vue'),
    ModuleProductFeaturedComparison: () => import('~/components/ModuleProductFeaturedComparison.vue'),
    ModuleProductSimilar: () => import('~/components/ModuleProductSimilar.vue'),
    ModuleProducts: () => import('~/components/ModuleProducts.vue'),
    ModuleRace: () => import('~/components/ModuleRace.vue'),
    ModuleRAHighlight: () => import('~/components/ModuleRAHighlight.vue'),
    ModuleRecommendations: () => import('~/components/ModuleRecommendations.vue'),
    ModuleSlider: () => import('~/components/ModuleSlider.vue'),
    ModuleTexto: () => import('~/components/ModuleTexto.vue'),
    ModuleTops: () => import('~/components/ModuleTops.vue'),
    ModuleYoutube: () => import('~/components/ModuleYoutube.vue'),
    SliderCardArticle: () => import('~/components/SliderCardArticle.vue'),
    SliderCardFinder: () => import('~/components/SliderCardFinder.vue'),
    SliderCardProduct: () => import('~/components/SliderCardProduct.vue'),
    SliderCardPromocion: () => import('~/components/SliderCardPromocion.vue'),
    SliderCardPromocionTemporal: () => import('~/components/SliderCardPromocion.vue')
  },
  props: {
    item: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    cleanObject (val) {
      if (Array.isArray(val)) {
        return val.filter(e => Object.keys(e).length > 0)
      } else {
        return val
      }
    }
  }
}
