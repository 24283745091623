//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
export default {
  props: {
    race: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      reset: false,
      projectData,
      userInfo: {
        _p: `${this.$config.id}`,
        race: this.race.title,
        name: '',
        email: '',
        tlf: '',
        days: [],
        hour: '',
        extra: '',
        errorEmail: '',
        validEmail: true,
        validCheck: true
      },
      submitted: false,
      error: ''
    }
  },
  methods: {
    isValidEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.userInfo.errorEmail = ''
      if (this.userInfo.email === '') {
        this.userInfo.validEmail = false
        this.userInfo.errorEmail += this.$t('UserRegisterForm.required') + ' '
      } else {
        this.userInfo.validEmail = true
      }
      if (!re.test(this.userInfo.email)) {
        this.userInfo.validEmail = false
        this.userInfo.errorEmail += this.$t('register.incorrect_format') + ' '
      } else {
        this.userInfo.validEmail = true
      }
    },
    isFormValid () {
      this.isValidEmail()
      if (
        this.userInfo.validEmail &&
        this.userInfo.name &&
        this.userInfo.email &&
        this.userInfo.tlf &&
        this.userInfo.days &&
        this.userInfo.hour &&
        this.userInfo.validCheck
      ) {
        return true
      } else {
        return false
      }
    },
    resetForm () {
      this.userInfo.email = ''
      this.userInfo.name = ''
      this.userInfo.tlf = ''
      this.userInfo.days = ''
      this.userInfo.hour = ''
      this.userInfo.extra = ''
    },
    async send () {
      const bodyFormData = new FormData()
      if (this.isFormValid()) {
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('race', this.userInfo.race)
        bodyFormData.append('name', this.userInfo.name)
        bodyFormData.append('email', this.userInfo.email)
        bodyFormData.append('tlf', this.userInfo.tlf)
        bodyFormData.append('days', this.userInfo.days)
        bodyFormData.append('hour', this.userInfo.hour)
        bodyFormData.append('extra', this.userInfo.extra)
        try {
          await this.$axios.post(this.$config.api + '/app/v1/races/verify', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            if (resp.status === 200) {
              this.error = this.$t('verify.verified')
              this.resetForm()
            }
          })
        } catch (error) {
          this.userInfo.message = this.$t('verify.not_verified')
        }
      } else {
        this.error = !this.userInfo.validCheck ? this.$t('verify.accept') : this.$t('verify.missing_fields')
      }
    }
  }
}
