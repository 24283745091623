var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAMP)?_c('div',{staticClass:"LstFlt filters",class:{ active: _vm.filterActive }},[_c('div',{staticClass:"filters-panel"},[_c('amp-accordion',{attrs:{"disable-session-states":"","expand-single-section":""}},[_vm._l((_vm.filtersView),function(filter,id){return [(
            !(_vm.type === 'product_prices' && filter.id === 'brand') &&
              filter.values.length &&
              !(
                filter.values.length === 1 &&
                filter.values[0].total === _vm.total &&
                filter.active === 0
              )
          )?_c('section',{key:id,class:[
            'filter-list',
            ("filter-list--" + (_vm.setFilterType(filter.id)))
          ]},[_c('h3',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(filter.id),expression:"filter.id"}],staticClass:"filter-title"},[_vm._v("\n            "+_vm._s(_vm.$t('list.filters.' + filter.id))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"filter-content filter-container",attrs:{"id":filter.id}},[_c('ul',{ref:filter.id,refInFor:true,staticClass:"filter-lists"},_vm._l((filter.values),function(data){return _c('li',{key:data.id || (Math.random() * 100000),staticClass:"filter-item"},[(data.finalUrl)?_c('nuxt-link',{class:filter.id === 'colour' ? ("" + (data.id)) : '',attrs:{"to":data.finalUrl}},[(data && data.name && data.name != '')?[_vm._v("\n                    "+_vm._s(data.name)+"\n                  ")]:_vm._e(),_vm._v(" "),(!data.name)?[_vm._v("\n                    "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.config))+"\n                  ")]:_vm._e()],2):_vm._e()],1)}),0)])]):_vm._e()]})],2)],1)]):_c('div',{staticClass:"LstFlt filters",class:{ active: _vm.filterActive }},[_c('div',{staticClass:"filters-panel"},[(_vm.textSearch.active)?[_c('TextSearchFilter',{attrs:{"text":_vm.textSearch.text,"search":true}})]:_vm._e(),_vm._v(" "),_vm._l((_vm.filtersFilteredFixed),function(filter,id){return [(
          !(_vm.type === 'product_prices' && filter.id === 'brand') && filter.values.length
        )?_c('div',{key:id,class:[
          'filter-list',
          ("filter-list--" + (_vm.setFilterType(filter.id)))
        ]},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(filter.id),expression:"filter.id"}],staticClass:"filter-title selected"},[_vm._v(_vm._s(_vm.$t('list.filters.' + filter.id))+"\n          "),(filter.values.filter(function (p) { return p.active === 1; }).length > 0)?_c('span',{staticClass:"selected-num"},[_vm._v(_vm._s(filter.values.filter(function (p) { return p.active === 1; }).length))]):_vm._e()]),_vm._v(" "),(filter.id === 'brand' && _vm.countBrandsFilter > 0)?_c('div',{staticClass:"filter-container collapse show fixed",class:{'show-more-modal': _vm.visibleMoreBrand},attrs:{"id":"brand-fixed"}},[_c('ul',{ref:filter.id+'-ul',refInFor:true},[_vm._l((filter.values.slice(0, _vm.countBrandsFilter)),function(data){return _c('li',{key:data.id || (Math.random() * 100000),staticClass:"filter"},[((data.name || data.id) && data.finalUrl)?[(data.nofollow)?_c('span',{class:[data.id, { active: data.active === 1 }],on:{"click":function($event){_vm.storeFilters(
                      filter.id,
                      data.id,
                      _vm.filters.url,
                      _vm.config,
                      _vm.type,
                      _vm.location,
                      data.active,
                      _vm.filters.highlight.includes(filter.id) ? true : false,
                      _vm.product.content_id && _vm.product.content_id !== ''
                        ? _vm.product.content_id
                        : false,
                      _vm.filters,
                      _vm.$route.query
                    ),
                    function (event) { return event.preventDefault(); }}}},[(data && data.name && data.name != '' && filter.id)?[_vm._v("\n                    "+_vm._s(data.name)+"\n                  ")]:_vm._e(),_vm._v(" "),(!data.name && filter.id)?[_vm._v("\n                    "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.config))+"\n                  ")]:_vm._e()],2):_c('nuxt-link',{class:[data.id, { active: data.active === 1 }],attrs:{"to":data.finalUrl,"title":_vm.getFilterValSeo(filter.id, data.name ? data.name : data.id, _vm.config),"event":""},nativeOn:{"click":function($event){_vm.storeFilters(
                      filter.id,
                      data.id,
                      _vm.filters.url,
                      _vm.config,
                      _vm.type,
                      _vm.location,
                      data.active,
                      _vm.filters.highlight.includes(filter.id) ? true : false,
                      _vm.product.content_id && _vm.product.content_id !== ''
                        ? _vm.product.content_id
                        : false,
                      _vm.filters,
                      _vm.$route.query
                    ),
                    function (event) { return event.preventDefault(); }}}},[(data && data.name && data.name != '' && filter.id)?[_vm._v("\n                    "+_vm._s(data.name)+"\n                  ")]:_vm._e(),_vm._v(" "),(!data.name && filter.id)?[_vm._v("\n                    "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.config))+"\n                  ")]:_vm._e()],2)]:_vm._e()],2)}),_vm._v(" "),(_vm.visibleMoreBrand)?_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(filter.id),expression:"filter.id"}],staticStyle:{"text-align":"right","display":"block"}},[_c('a',{staticClass:"link-in",attrs:{"href":"#","title":_vm.$t('list_filters.more_brands')}},[_vm._v("\n                "+_vm._s(_vm.$t('list_filters.more_brands'))+"\n              ")])]):_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('b-collapse',{staticClass:"filter-container",class:{ 'show-mb': filter.id === 'size', 'collapse-modal': _vm.visibleMoreBrand && filter.id === 'brand',
                    'collapse-modal-block': !_vm.visibleMoreBrand && filter.id === 'brand'},attrs:{"id":filter.id,"visible":filter.active === 1 && _vm.flagFiltersSelectFixed ? true : false,"visibleMoreBrand":""}},[_c('ul',{ref:filter.id,refInFor:true},_vm._l((filter.values),function(data,index){return _c('li',{key:data.id || (Math.random() * 100000),staticClass:"filter"},[((data.name || data.id) && data.finalUrl && !(filter.id === 'brand' && index < _vm.countBrandsFilter))?[(data.nofollow)?_c('span',{class:[data.id, { active: data.active === 1 }],on:{"click":function($event){_vm.storeFilters(
                      filter.id,
                      data.id,
                      _vm.filters.url,
                      _vm.config,
                      _vm.type,
                      _vm.location,
                      data.active,
                      _vm.filters.highlight.includes(filter.id) ? true : false,
                      _vm.product.content_id && _vm.product.content_id !== ''
                        ? _vm.product.content_id
                        : false,
                      _vm.filters,
                      _vm.$route.query
                    ),
                    function (event) { return event.preventDefault(); }}}},[(data && data.name && data.name != '' && filter.id)?[_vm._v("\n                    "+_vm._s(data.name)+"\n                  ")]:_vm._e(),_vm._v(" "),(!data.name && filter.id)?[_vm._v("\n                    "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.config))+"\n                  ")]:_vm._e()],2):_c('nuxt-link',{class:[data.id, { active: data.active === 1 }],attrs:{"to":data.finalUrl,"title":_vm.getFilterValSeo(filter.id, data.name ? data.name : data.id, _vm.config),"event":""},nativeOn:{"click":function($event){_vm.storeFilters(
                      filter.id,
                      data.id,
                      _vm.filters.url,
                      _vm.config,
                      _vm.type,
                      _vm.location,
                      data.active,
                      _vm.filters.highlight.includes(filter.id) ? true : false,
                      _vm.product.content_id && _vm.product.content_id !== ''
                        ? _vm.product.content_id
                        : false,
                      _vm.filters,
                      _vm.$route.query
                    ),
                    function (event) { return event.preventDefault(); }}}},[(data && data.name && data.name != '' && filter.id)?[_vm._v("\n                    "+_vm._s(data.name)+"\n                  ")]:_vm._e(),_vm._v(" "),(!data.name && filter.id)?[_vm._v("\n                    "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.config))+"\n                  ")]:_vm._e()],2)]:_vm._e()],2)}),0)])],1):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"filters-panel-footer"},[_c('button',{staticClass:"btn btn-primary btn-lg btn-noborder",on:{"click":function($event){_vm.filterActive = false}}},[_vm._v("\n        "+_vm._s(_vm.$t('list_filters.show'))+" "),_c('strong',[_vm._v(_vm._s(_vm.$t('list_filters.results')))])])])],2),_vm._v(" "),(_vm.showSmallBtn)?_c('button',{staticClass:"filters-small-btn",on:{"click":function($event){return _vm.toggleFilters(true)}}},[_vm._v("\n    "+_vm._s(_vm.$t('list_filters.filters'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"rn-filters-footer shadow-lg p-3 d-md-none"},[_c('nav',[(_vm.product && _vm.type === 'product_prices')?_c('span',{staticClass:"rn-nav-link btn",on:{"click":function($event){return _vm.addProd2Compare(_vm.product, _vm.config.paths.home)}}},[_vm._v("\n        + "+_vm._s(_vm.$t('product_page.compare'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showFilterBtn)?_c('span',{staticClass:"rn-nav-link green btn",on:{"click":function($event){return _vm.toggleFilters(true)}}},[_vm._v("\n        "+_vm._s(_vm.$t('list_filters.filters'))+"\n      ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }