//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
export default {
  props: {
    menuMagazine: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    section: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    device: {
      type: String,
      default: 'desktop'
    },
    selection: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selected: this.selection,
      projectData
    }
  },
  methods: {
    redirect (event) {
      // window.location.href = '/articulos/categorias/' + event + '/'
      this.$router.push(event)
    }
  }
}
