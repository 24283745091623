//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  async asyncData ({ app, store, $axios, route, $config, $md5, error }) {
    // Get Zones data
    const ax = new AxiApi($config.api)
    let call = $config.static + '/json/zones/pages/zones.json'
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      zones = {}
    }
    await ax.createComponentData(zones.data, store)

    // Get JSON data
    call = `${$config.static}/json/pages${route.path}index.json`
    const code = $md5(call)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', call)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const calculator = store.state.lists[code]

    // Metas and head
    const head = {
      title: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`
        },
        {
          hid: 'description',
          name: 'description',
          content: (calculator.metas.description !== '') ? `${calculator.metas.description}` : `${calculator.entrance}`
        },
        {
          property: 'og:url',
          content: `${$config.domain}${calculator.metas.canonical}`
        },
        {
          property: 'og:title',
          content: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`
        },
        {
          property: 'og:description',
          content: (calculator.metas.description !== '') ? `${calculator.metas.description}` : `${calculator.entrance}`
        }
      ],
      link: [{
        rel: 'canonical',
        href: `${$config.domain}${calculator.metas.canonical}`
      }]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'calculator_marathon', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      head,
      calculator
    }
  },
  data () {
    return {
      projectData,
      distance: '42195',
      rhythm: '',
      time: '',
      distanceResult: '-',
      rhythmResult: '-',
      timeResult: '-',
      error: '',
      read: false
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    segundos2Hora (seconds) {
      if (!isNaN(seconds)) {
        const horas = Math.floor(seconds / 3600)
        const segundosRestantes = seconds % 3600
        const minutos = Math.floor(segundosRestantes / 60)
        const segundos = Math.floor(segundosRestantes % 60)
        return ((horas > 0) ? horas + ':' : '') + (((minutos < 10) && (horas > 0)) ? '0' + minutos : minutos) + ':' + ((segundos < 10) ? '0' + segundos : segundos)
      } else { return false }
    },
    hora2segundos (hora) {
      if (hora.match(/^([0-9]+(:([0-5][0-9]|[0-9]))?|[0-9]+:([0-5][0-9]|[0-9]):([0-5][0-9]|[0-9]))$/)) {
        const datos = hora.split(':')
        if (datos.length === 3) { return (parseInt(datos[0]) * 3600) + (parseInt(datos[1]) * 60) + parseInt(datos[2]) } else if (datos.length === 2) { return (parseInt(datos[0]) * 60) + parseInt(datos[1]) } else { return (parseInt(datos[0]) * 60) }
      } else { return false }
    },
    calcular () {
      if (this.$refs.calcButton.textContent !== 'Modificar') {
        if (this.rhythm === '' && this.time !== '') {
          const secondTime = this.hora2segundos(this.time)
          this.rhythm = this.formatZero(this.segundos2Hora(secondTime * 1000 / this.distance), 'rhythm')
        } else if (this.rhythm !== '' && this.time === '') {
          const secondRhythm = this.hora2segundos(this.rhythm)
          this.time = this.formatZero(this.segundos2Hora(secondRhythm * this.distance / 1000), 'time')
        } else if (this.rhythm !== '' && this.time !== '') {
          this.error = this.$t('calculator.error_empty')
          return
        } else {
          this.error = this.$t('calculator.error_need')
          return
        }
        if (this.checkInvalidValues() === false) {
          this.$refs.calcButton.textContent = 'Modificar'
          this.read = true
          this.error = ''
          this.distanceResult = this.distance
          this.rhythmResult = this.rhythm
          this.timeResult = this.time
        }
      } else {
        this.$refs.calcButton.textContent = 'Calcular'
        this.read = false
        this.distanceResult = '-'
        this.rhythmResult = '-'
        this.timeResult = '-'
        this.rhythm = ''
        this.time = ''
      }
    },
    formatZero (time, type) {
      if (type === 'rhythm') {
        while (time.length < 5) { time = '0' + time }
      } else {
        while (time.length < 8) {
          if (time.length === 5) { time = ':' + time }
          time = '0' + time
        }
      }
      return time
    },
    checkInvalidValues () {
      if (this.rhythm.length > 5 || this.time.length > 8 || this.rhythm > '23:59' || this.time > '23:59:59') {
        this.error = this.$t('calculator.error_not_valid')
        this.distanceResult = '-'
        this.rhythmResult = '-'
        this.timeResult = '-'
        this.rhythm = ''
        this.time = ''
        return true
      }
      return false
    }
  }
}
