
export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export const isValidPassword = (password) => {
  return /^(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S{8,}$/.test(password)
}

export const isValidPostalCode = (code) => {
  return /^[0-9]{5}$/.test(code)
}

export const validate = (formData) => {
  let validateForm = true
  Object.values(formData).forEach(field => {
    if (field.validate && !field.validate()) {
      validateForm = false
      return false
    }
  })

  return validateForm
}
