//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
import global from '~/mixins/global.js'
export default {
  name: 'ModuleComments',
  ...(process.env.cache && {serverCacheKey: props => murmurhash.v2(JSON.stringify(props))}),
  mixins: [global],
  props: {
    item: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      title: `${this.item?.brand?.name ?? ''} ${this.item?.name ?? (this.item?.title ?? '')}`
    }
  },
  methods: {
    getShownQuestions (total) {
      if (total >= 5) { return 5 } else { return total }
    }
  }
}
