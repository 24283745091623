//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'
export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ params, route, app, store, error, $axios, $config, $md5 }) {
    try {
      const routeProps = route.matched[0].props.default

      // Get Sections Data & Set Section
      const sectQry = $config.static + '/json/articles/sections.json'
      const sectCode = $md5(sectQry)
      if (
        typeof store.state.lists[sectCode] === 'undefined' ||
        Object.keys(store.state.lists[sectCode]).length === 0
      ) {
        await store.dispatch('loadLists', sectQry)
      }
      const sections = store.state.lists[sectCode]
      let section = false
      if (params.categoria) {
        section = sections.find(s => s.id === params.categoria)
        if (typeof section === 'undefined') {
          return error({ statusCode: 404, message: 'No section' })
        }
      }
      let selected = false
      if (section) {
        selected = `/${projectData.contents.articles.all.paths.home}/${projectData.contents.articles.all.paths.categories}/${section.id}/`
      }

      let menuMagazine = []
      menuMagazine = sections.filter(s => s.isInMenu === '1')
      menuMagazine.sort((a, b) =>
        a.menuPos > b.menuPos ? 1 : b.menuPos > a.menuPos ? -1 : 0
      )

      // Get Authors Data & Set Author
      let author = false
      if (params.autor) {
        const authorQry = `${$config.static}/json/authors/${params.autor}.json`
        const authorCode = $md5(authorQry)
        if (
          typeof store.state.lists[authorCode] === 'undefined' ||
          Object.keys(store.state.lists[authorCode]).length === 0
        ) {
          await store.dispatch('loadLists', authorQry)
        }
        author = store.state.lists[authorCode]
      }

      // Zones & Heritages
      const ax = new AxiApi($config.api)
      let call = ''
      if (section !== false) {
        call = `${$config.static}/json/zones/articles/${section.content_id}/zones.json`
      } else {
        call = $config.static + '/json/zones/articles/zones.json'
      }
      let zones
      try {
        zones = await $axios.get(call)
      } catch (err) {
        zones = {}
      }
      await ax.createComponentData(zones.data, store)

      // Breadcrumb
      const breadcrumb = routeProps.breadcrumb

      // Get articles API data
      let filters2api = ''
      let queryText = ''
      let page = 1
      if (params.pag && params.pag > 0) {
        page = params.pag
      }
      if (section !== false) {
        filters2api += `/category-${section.id}`
      }
      if (author !== false) {
        filters2api += `/author-${author.id}`
      }
      if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
        queryText = route.query.q
      } else if (routeProps.filter === 'tag') {
        queryText = params.etiqueta.replace(/[^\d\w]/, ' ').trim()
        queryText = queryText.charAt(0).toUpperCase() + queryText.slice(1)
      }

      const query = `${$config.api}/app/v1/articles/list?_p=${$config.id}&filters=${filters2api}&text=${queryText}&page=${page}`
      const code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }
      const articleList = store.state.lists[code]

      // elements
      const elements = {
        title: true,
        photo: true,
        text: true,
        date: true
      }

      // Metas & Head
      let h1 = app.i18n.t('article_list.page_title')
      let metaTitle = app.i18n.t('article_list.meta_title')
      let metaKeywords = app.i18n.t('article_list.meta_keywords')
      let metaDescr = app.i18n.t('article_list.meta_description')
      if (section !== false) {
        h1 = app.i18n
          .t('article_list.page_title_section')
          .replace(/{seo}/g, section.name)
        metaTitle = app.i18n
          .t('article_list.meta_title_section')
          .replace(/{seo}/g, section.name)
        metaKeywords = app.i18n
          .t('article_list.meta_keywords_section')
          .replace(/{seo}/g, section.name)
        metaDescr = app.i18n
          .t('article_list.meta_description_section')
          .replace(/{seo}/g, section.name)
      }
      if (author !== false) {
        h1 = app.i18n
          .t('article_list.page_title_author')
          .replace(/{seo}/g, author.name)
        metaTitle = app.i18n
          .t('article_list.meta_title_author')
          .replace(/{seo}/g, author.name)
        metaKeywords = app.i18n
          .t('article_list.meta_keywords_author')
          .replace(/{seo}/g, author.name)
        metaDescr = app.i18n
          .t('article_list.meta_description_author')
          .replace(/{seo}/g, author.name)
      }
      if (queryText !== '' && routeProps.filter === 'tag') {
        h1 = app.i18n
          .t('article_list.page_title_section')
          .replace(/{seo}/g, queryText)
        metaTitle = app.i18n
          .t('article_list.meta_title_tag_section')
          .replace(/{seo}/g, queryText)
        metaDescr = app.i18n
          .t('article_list.meta_description_section')
          .replace(/{seo}/g, queryText)
        metaKeywords += `, ${queryText}`
      } else if (queryText !== '') {
        h1 = app.i18n
          .t('article_list.page_title_text')
          .replace(/{seo}/g, queryText)
        metaTitle = app.i18n
          .t('article_list.meta_title_text')
          .replace(/{seo}/g, queryText)
      }
      const header = {
        h1
      }
      const head = {
        title: `${metaTitle} | Runnea`,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: `${metaTitle} | Runnea`
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: `${metaKeywords}`
          },
          {
            hid: 'description',
            name: 'description',
            content: `${metaDescr}`
          }
        ],
        link: [
          {
            rel: 'canonical',
            href: `${$config.domain}${route.path}`
          }
        ]
      }

      if (Object.keys(route.query).length > 0) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      }

      const zonesContent = ax.get()

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'article_list', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
        })
      }

      return {
        zones: zonesContent,
        breadcrumb,
        articleList,
        path: route.path,
        head,
        header,
        elements,
        sections,
        section,
        author,
        selected: selected || `/${projectData.contents.articles.all.paths.home}/`,
        menuMagazine
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      // selected: '/articulos/'
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
