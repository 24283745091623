//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { filtersUrlIsIndexable } from '~/lib/seo'
export default {
  mixins: [global],
  props: {
    type: {
      type: String,
      default: ''
    },
    filters: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    config: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    location: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: ''
    },
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    total: {
      type: Number,
      default: 0
    },
    showSmallBtn: {
      type: Boolean,
      default: false
    },
    showFilterBtn: {
      type: Boolean,
      default: true
    },
    textSearch: {
      type: Object,
      default: () => {
        return { active: false, text: '' }
      }
    }
  },
  data () {
    return {
      filterActive: false,
      filterTypeFilters: true, // true: filtros, false: tallas
      iconChevronDown: require('~/assets/img/icons/icon-chevron-down-grey.svg'),
      visibleMoreBrand: true,
      featuredBrandsFilter: [],
      countBrandsFilter: 0,
      filtersFilteredFixed: [],
      flagFiltersSelectFixed: false
    }
  },
  computed: {
    filtersView () {
      let filtersFiltered = this.filters.view.filter(
        f => (f.id === 'size' && !this.filterTypeFilters) || this.filterTypeFilters
      )
      if (
        this.type === 'product_prices' &&
        this.config.metas?.product_prices?.filters
      ) {
        filtersFiltered = filtersFiltered.filter((f) => {
          let filterExists = false
          Object.values(this.config.metas.product_prices.filters).forEach(
            (filters) => {
              filterExists = filterExists || filters.includes(f.id)
            }
          )
          return filterExists
        })
      }

      const originalFiltersActive = {}
      if (this.filters.active) {
        this.filters.view.forEach((filter) => {
          if (this.filters.active[filter.id]) {
            const valuesActive = []
            filter.values.forEach((value) => {
              if (this.filters.active[filter.id].includes(value.id)) {
                valuesActive.push(value.id)
              }
            })

            if (valuesActive.length > 0) {
              originalFiltersActive[filter.id] = valuesActive
            }
          }
        })
      }

      filtersFiltered.map((filter) => {
        const filterMapped = filter
        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value
            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.filters.url,
              this.config,
              this.type,
              this.location,
              this.$route.query
            )
            const customFiltersActive = JSON.parse(JSON.stringify(originalFiltersActive))
            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }
            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.config, this.type)
            return valueMapped
          })
        }
        return filterMapped
      })

      return filtersFiltered
    },
    filtersActive () {
      let filtersViewActive = [...this.filters.view]
      filtersViewActive = filtersViewActive.filter(
        f => (f.active === 1)
      )
      const filtersActive = {}
      filtersViewActive = filtersViewActive.map(
        filter => {
          const newValues = filter.values.filter(
            value => (value.active === 1)
          )
          return { ...filter, values: newValues }
        })
      filtersViewActive.forEach(
        filter => {
          filtersActive[filter.id] = filter.values.length
        }
      )
      return filtersActive
    }
  },
  async mounted () {
    document.body.classList.add('with-bottom-bar')
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'brand') {
        this.visibleMoreBrand = !isJustShown
      }
    })
    try {
      await this.$store.dispatch('loadBrands').then(() => {
        this.featuredBrandsFilter = this.$store.state.brands.filter(f => (f.featured_filter === '1' && f.type_product.some(element => element === this.config.id)))
      })
      const filters = JSON.parse(JSON.stringify(this.filters.view)) // Make a copy
      let brandFilter

      for (const filter of filters) {
        if (filter.id === 'brand') {
          brandFilter = filter
          break
        }
      }

      if (brandFilter) {
        filters.splice(filters.indexOf(brandFilter), 1)
        filters.unshift(brandFilter)
      }
      const brandViewObject = filters.find((viewObject) => viewObject.id === 'brand')

      if (brandViewObject) {
        const brandValues = brandViewObject.values
        const rearrangedValues = []
        for (const brand of this.featuredBrandsFilter) {
          const brandValueIndex = brandValues.findIndex((value) => value.id === brand.id)

          if (brandValueIndex !== -1) {
            this.countBrandsFilter++
            rearrangedValues.push(brandValues[brandValueIndex])
            brandValues.splice(brandValueIndex, 1)
          }
        }
        rearrangedValues.push(...brandValues)
        brandViewObject.values = rearrangedValues
        filters.map((filter) => {
          if (filter.id === 'brand') {
            filter = brandViewObject
          }
          return filter
        })
      }

      this.filtersFilteredFixed = filters.filter(
        f => (f.id === 'size' && !this.filterTypeFilters) || this.filterTypeFilters
      )
      if (
        this.type === 'product_prices' &&
          this.config.metas?.product_prices?.filters
      ) {
        this.filtersFilteredFixed = this.filtersFilteredFixed.filter((f) => {
          let filterExists = false
          Object.values(this.config.metas.product_prices.filters).forEach(
            (filters) => {
              filterExists = filterExists || filters.includes(f.id)
            }
          )
          return filterExists
        })
      }

      const originalFiltersActive = {}
      if (this.filters.active) {
        filters.forEach((filter) => {
          if (this.filters.active[filter.id]) {
            const valuesActive = []
            filter.values.forEach((value) => {
              if (this.filters.active[filter.id].includes(value.id)) {
                valuesActive.push(value.id)
              }
            })

            if (valuesActive.length > 0) {
              originalFiltersActive[filter.id] = valuesActive
            }
          }
        })
      }

      this.filtersFilteredFixed.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value
            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.filters.url,
              this.config,
              this.type,
              this.location,
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(originalFiltersActive))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.config, this.type)

            return valueMapped
          })
        }

        return filterMapped
      })
      let flag = false
      const arraySlice = this.filtersFilteredFixed[0].values.slice(0, this.countBrandsFilter)
      if (originalFiltersActive.brand) {
        for (let i = 0; i < originalFiltersActive.brand.length; i++) {
          if (arraySlice.some(item => item.id === originalFiltersActive.brand[i])) {
            flag = true
          } else {
            flag = false
            break
          }
        }
      }
      this.flagFiltersSelectFixed = !flag
    } catch (err) {
      console.log(err)
    }
  },
  beforeDestroy () {
    document.body.classList.remove('with-bottom-bar')
  }
}
