
import '~/static/cookieconsent/cookieconsent.css'
import '~/static/cookieconsent/cookieconsent.js'
import cookieConsentConfig from '~/static/cookieconsent/cookieconsent.config'

export default (actions, texts) => {
  const cookieConsent = window.initCookieConsent()
  cookieConsent.run(cookieConsentConfig(actions, texts))

  window.cookieConsent = cookieConsent
}
