//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import * as formFunctions from '~/mixins/form.functions.js'
import { setAuthNewsletter } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'

export default {
  layout: 'clean.close',
  data () {
    const head = {
      title: 'Runnea - Auth Newsletter',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    const signinData = {}

    if (projectData.newsletter?.lists) {
      projectData.newsletter.lists.forEach(list => {
        signinData[`list${list.id}`] = {
          value: this.$auth.user?.lists && this.$auth.user?.lists.includes(list.id)
        }
      })
    }

    return {
      projectData,
      formValidated: false,
      signinData,
      newsletter: projectData.newsletter,
      feedback: {
        valid: '',
        invalid: ''
      },
      sexos: [
        { value: '', text: this.$t('user_page.select'), disabled: true },
        { value: 'man', text: this.$t('register.man') },
        { value: 'woman', text: this.$t('register.woman') }
      ],
      authId: this.$route.query._ai,
      authToken: this.$auth.strategy.token.get(),
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (!this.$auth.loggedIn) {
      this.$router.push({
        path: '/auth/login',
        query: {
          _ai: this.authId
        }
      })
    } else if (!this.newsletter?.lists?.length) {
      setAuthNewsletter(this.authId, false)
      this.$router.push({
        path: '/auth/redirect',
        query: {
          _ai: this.authId
        }
      })
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.signinData[field]?.validate || this.signinData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.signinData)
    },
    isRequired (field, value = '') {
      return (this.signinData[field].value !== value)
    },
    async update () {
      this.formValidated = true
      if (this.validateForm()) {
        const bodyFormData = new URLSearchParams()
        bodyFormData.append('_p', this.$config.id)
        if (this.newsletter?.lists) {
          this.newsletter.lists.forEach(list => {
            bodyFormData.append(`list${list.id}`, (this.signinData[`list${list.id}`].value && 1) || 0)
          })
        }
        await this.$axios.put(this.$config.api + '/app/v1/users/data', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: this.authToken
          }
        }).then((resp) => {
          if (resp.status === 200) {
            setAuthNewsletter(this.authId, false)
            this.$router.push({
              path: '/auth/redirect',
              query: {
                _ai: this.authId
              }
            })
          } else {
            this.feedback.invalid = resp.statusText
          }
        })
      }
    }
  }
}
