//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    chatId: {
      type: String,
      default: 'chatId'
    }
  },
  data () {
    return {
      newComment: {
        text: '',
        suscription: false
      },
      successMsg: ''
    }
  },
  mounted () {
    applyAuthAction.apply(this)
  },
  methods: {
    addComment () {
      openAuthRequired.apply(this, [null, {
        name: 'submitComment',
        params: [
          this.chatId,
          this.newComment.text
        ]
      }, 'modal', 'forumComment'])
    },
    submitComment (chatId, text) {
      const searchParams = new URLSearchParams()
      searchParams.append('chat_id', parseInt(chatId, 10))
      searchParams.append('text', text)
      try {
        this.$axios.put(this.$config.api + '/app/v1/users/chats', searchParams, {
          headers: {
            Authorization: this.$auth.strategy.token.get()
          }
        }).then((resp) => {
          if (resp.status === 200) {
            this.successMsg = `${this.$t('newCommentModule.thanks_msg')}`
            this.$refs['succes-modal'].show()
          } else {
            this.successMsg = `${this.$t('newCommentModule.error_msg')}`
            this.$refs['succes-modal'].show()
          }
        })
      } catch (error) {
        this.successMsg = `${this.$t('newCommentModule.error_msg')}`
        this.$refs['succes-modal'].show()
      }
      this.newComment.text = ''
    }
  }
}
