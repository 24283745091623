//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getAuthId } from '~/lib/auth'
import projectData from '~/static/config/project.json'
import * as formFunctions from '~/mixins/form.functions.js'

export default {
  props: {
    modal: {
      type: Boolean,
      default: () => false
    },
    border: {
      type: Boolean,
      default: () => true
    },
    id: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      projectData,
      formValidated: false,
      showPassword: false,
      loginData: {
        email: {
          value: '',
          validate: () => this.isEmail()
        },
        password: {
          value: '',
          validate: () => this.isRequired('password')
        }
      },
      feedback: {
        valid: '',
        invalid: ''
      },
      authId: this.id || (this.modal && getAuthId()) || this.$route.query._ai
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.loginData[field]?.validate || this.loginData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.loginData)
    },
    isRequired (field, value = '') {
      return (this.loginData[field].value !== value)
    },
    isEmail () {
      return this.isRequired('email') && formFunctions.isValidEmail(this.loginData.email.value)
    },
    loginWithGoogle () {
      this.$auth.loginWith('google', {
        params: {
          state: this.authId || ''
        }
      })
    },
    loginUser () {
      this.formValidated = true
      if (this.validateForm()) {
        try {
          const bodyFormData = new FormData()
          bodyFormData.append('_p', this.$config.id)
          bodyFormData.append('email', this.loginData.email.value)
          bodyFormData.append('password', this.loginData.password.value)
          this.$auth.loginWith('local', { data: bodyFormData }).then((resp) => {
            if (resp.status === 200) {
              this.$router.push({
                path: '/auth/redirect',
                query: {
                  _ai: this.authId
                }
              })
            } else {
              this.feedback.invalid = resp.statusText
            }
          }).catch(() => {
            this.feedback.invalid = this.$t('UserAuthForm.wrong_password')
          })
        } catch (err) {
          this.feedback.invalid = this.$t('UserAuthForm.no_email')
        }
      }
    }
  }
}
