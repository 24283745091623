var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PgeAthRmm p-4 text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t('login.remmember_password')))]),_vm._v(" "),_c('b-container',{staticClass:"p-3 text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('UserPasswordForm.new_password')))]),_vm._v(" "),_c('b-form',{staticClass:"text-left"},[_c('b-form-group',{attrs:{"label":("* " + (_vm.$t('UserRegisterForm.email'))),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","label":"Email","placeholder":_vm.$t('UserRegisterForm.email'),"state":_vm.fieldState('email')},model:{value:(_vm.rememberData.email.value),callback:function ($$v) {_vm.$set(_vm.rememberData.email, "value", $$v)},expression:"rememberData.email.value"}}),_vm._v(" "),_c('b-form-invalid-feedback',[_vm._v("\n          "+_vm._s(_vm.isRequired('email') ? _vm.$t('register.incorrect_format') : _vm.$t('UserRegisterForm.required'))+"\n        ")])],1),_vm._v(" "),_c('b-form-invalid-feedback',{staticClass:"text-center mb-4",attrs:{"state":!_vm.formValidated ? null : _vm.validateForm()}},[_vm._v("\n        "+_vm._s(_vm.$t('register.missing_fields'))+"\n      ")]),_vm._v(" "),_c('b-form-invalid-feedback',{staticClass:"text-center mb-4",attrs:{"state":_vm.feedback.invalid && false}},[_vm._v("\n        "+_vm._s(_vm.feedback.invalid)+"\n      ")]),_vm._v(" "),_c('b-form-valid-feedback',{staticClass:"text-center mb-4",attrs:{"state":_vm.feedback.valid && true}},[_vm._v("\n        "+_vm._s(_vm.feedback.valid)+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary full mb-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.sendPassword()}}},[_vm._v("\n        "+_vm._s(_vm.$t('login.send'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"form-link mb-4"},[_c('nuxt-link',{staticClass:"green-bottom-border",attrs:{"to":{
          path: '/auth/signin',
          query: {
            _ai: _vm.authId
          }
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('login.no_account'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"form-link"},[_c('nuxt-link',{staticClass:"green-bottom-border",attrs:{"to":{
          path: '/auth/login',
          query: {
            _ai: _vm.authId
          }
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('registration.already_account'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }