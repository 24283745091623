
import projectData from '~/static/config/project.json'
import axios from 'axios'

export function countriesData () {
  const countriesData = []

  const regions = []
  projectData.countries.forEach((country) => {
    if (country.region && !regions.includes(country.region)) {
      regions.push(country.region)
    }
  })

  regions.forEach((region) => {
    countriesData.push({
      region,
      countries: projectData.countries.filter((country) => {
        return country.region === region
      })
    })
  })

  return countriesData
}

export function projectsData () {
  const projectsData = []

  projectsData.push({
    id: projectData.id,
    loc_code: projectData.loc_code,
    country: projectData.country,
    currency: projectData.currency,
    static: `${projectData.domains.static.protocol}://${projectData.domains.static.domain}`
  })

  if (projectsData.subprojects) {
    projectsData.subprojects.forEach((subproject) => {
      projectsData.push(subproject)
    })
  }

  return projectsData
}

export function countryData (project = null, field = 'id') {
  project = project || projectData[field]
  const countryData = projectData.countries.find((country) => {
    return country[field] === project
  })
  if (countryData) {
    if (countryData.id === projectData.id) {
      countryData.static = `${projectData.domains.static.protocol}://${projectData.domains.static.domain}`
    } else {
      if (projectData.subprojects) {
        const subProjectData = projectData.subprojects.find((subproject) => {
          return subproject.id === project
        })
        if (subProjectData) {
          countryData.static = subProjectData.static
        }
      }
    }
  }

  return countryData || null
}

export const countryLocation = new Promise((resolve, reject) => {
  if (process.client) {
    const country = sessionStorage.getItem('countryLocation')
    if (!country) {
      axios.get('//get.geojs.io/v1/ip/country.json').then((response) => {
        if (response.data?.country) {
          sessionStorage.setItem('countryLocation', JSON.stringify(response.data))
          resolve(response.data)
        }
      }).catch((error) => {
        reject(error)
      })
    } else {
      resolve(JSON.parse(country))
    }
  }
})
