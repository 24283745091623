import axios from 'axios'
import { md5 } from 'pure-md5'

export const criticals = async (url, group = false, variants = false) => {
  const headStyle = []

  if (process.server && process.env.criticals) {
    group = group || url
    group += (variants && `.${md5(JSON.stringify(variants))}`) || ''

    const response = await axios.get(`http://localhost:${process.env.port}/server/critical-css?prefix=${process.env.prefix}&test=${process.env.test}&url=${url}&group=${group}`)
    let criticalCode = response && response.data

    if (criticalCode) {
      criticalCode += 'html body{font-family:"Arial";letter-spacing:0.65px;}'
      headStyle.push({
        cssText: criticalCode, type: 'text/css', 'data-origin': url, 'data-group': group
      })
    }
  }

  return headStyle
}
