//
//
//

export default {
  name: 'LytErr',
  layout: 'void',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
