//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    chatId: {
      type: String,
      default: 'chatId'
    },
    sectionId: {
      type: String,
      default: 'sectionId'
    }
  },
  data () {
    return {
      newComment: {
        text: '',
        suscription: true
      },
      successMsg: '',
      // Imagenes
      close: { alt: 'close', src: require('@/assets/img/icons/icon-close-white.svg'), width: 12, heigth: 7 },
      up: { alt: 'open', src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), width: 12, heigth: 7 },
      left: { alt: 'left', src: require('@/assets/img/icons/icon-arrow-back.svg'), width: 12, heigth: 7 }
    }
  },
  mounted () {
    applyAuthAction.apply(this)
  },
  methods: {
    addComment () {
      openAuthRequired.apply(this, [null, {
        name: 'showModal'
      }, 'modal', 'forumComment'])
    },
    showModal () {
      this.$bvModal.show('comment-modal')
    },
    hideModal () {
      this.$bvModal.hide('comment-modal')
    },
    submitComment () {
      const searchParams = new URLSearchParams()
      searchParams.append('chat_id', parseInt(this.chatId, 10))
      searchParams.append('section_id', parseInt(this.sectionId, 10))
      searchParams.append('suscription', this.newComment.suscription ? 1 : 0)
      searchParams.append('text', this.newComment.text)
      try {
        this.$axios.put(this.$config.api + '/app/v1/users/chats', searchParams, {
          headers: {
            Authorization: this.$auth.strategy.token.get()
          }
        }).then((resp) => {
          if (resp.status === 200) {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newCommentButton.thanks')}`
            window.location.reload()
          } else {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newCommentButton.try_again')}`
          }
        })
      } catch (error) {
        this.hideModal()
        this.$refs['succes-modal'].show()
        this.successMsg = `${this.$t('newCommentButton.try_again')}`
      }
    }
  }
}
