//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'clean',
  data () {
    return {
      runnealogo: { width: 143, height: 25, alt: 'Runnea logo', class: 'runnea-logo mb-4', src: require('@/assets/img/logo-header-runnea.svg') }
    }
  },
  mounted () {
    this.$router.push({
      path: this.$auth.user ? '/auth/redirect' : '/auth/login',
      query: {
        _ai: this.$route.query.state
      }
    })
  }
}
