import project from '~/static/config/project.json'

export default function ({ store, redirect, route }) {
  const routeProps = route.matched[0].props.default
  let TypeConfig = Object.values(project.contents.product_types).find(pt => pt.id === routeProps.prodTypeId)
  if (!TypeConfig) {
    TypeConfig = Object.values(project.contents.product_types_marketplaces).find(
      pt => pt.id === routeProps.prodTypeId
    )
  }
  const filters = {}
  let filters2api = ''
  let storeUrl = ''
  const pageName = routeProps.name
  let connotation = 'prices'
  let pageParams = route.params.pathMatch
  if (pageName) {
    if (pageName.includes('ProductList')) {
      connotation = 'products'
    }
    if (pageName.includes('ProductPrices')) {
      pageParams = route.params[1]
    }
    if ((!pageParams || pageParams === '') && (!route.params.tienda || route.params.tienda === '')) {
      if (store.state.filters.list[connotation] && store.state.filters.list[connotation][TypeConfig.id] && store.state.filters.list[connotation][TypeConfig.id].global) {
        for (const prop in store.state.filters.list[connotation][TypeConfig.id].global) {
          if (store.state.filters.list[connotation][TypeConfig.id].global[prop]) {
            filters[prop] = {}
            for (const val in store.state.filters.list[connotation][TypeConfig.id].global[prop]) {
              filters[prop][val] = true
            }
          }
        }
      }
      if (pageName.includes('ProductPrices')) {
        const prod = route.params.pathMatch.match(/^(.*?\/)?([0-9]+)\/?$/)
        if (store.state.filters.list[connotation] && store.state.filters.list[connotation][TypeConfig.id] && store.state.filters.list[connotation][TypeConfig.id][prod[2]]) {
          for (const prop in store.state.filters.list[connotation][TypeConfig.id][prod[2]]) {
            if (store.state.filters.list[connotation][TypeConfig.id][prod[2]][prop]) {
              filters[prop] = {}
              for (const val in store.state.filters.list[connotation][TypeConfig.id][prod[2]][prop]) {
                filters[prop][val] = true
              }
            }
          }
        }
      } else if (store.state.filters.list[connotation] && store.state.filters.list[connotation][TypeConfig.id] && store.state.filters.list[connotation][TypeConfig.id][TypeConfig.id]) {
        for (const prop in store.state.filters.list[connotation][TypeConfig.id][TypeConfig.id]) {
          if (store.state.filters.list[connotation][TypeConfig.id][TypeConfig.id][prop]) {
            filters[prop] = {}
            for (const val in store.state.filters.list[connotation][TypeConfig.id][TypeConfig.id][prop]) {
              filters[prop][val] = true
            }
          }
        }
      }
    }

    // Ordenando filtros
    if (TypeConfig && TypeConfig.filters && (Object.keys(filters).length > 0)) {
      TypeConfig.filters.forEach((filter) => {
        if (filter.share && project.filters_share && project.filters_share[filter.share]) {
          filter = { ...project.filters_share[filter.share], ...filter }
        }
        if (filter.id !== 'store') {
          if (typeof filters[filter.id] !== 'undefined' && filter.id !== 'brand') {
            const valConfig = []
            let valArr = Object.keys(filters[filter.id])
            if (filter.id === 'size') {
              valArr = valArr.sort((a, b) => a - b)
            }
            valArr.forEach((v) => {
              if (filter.values) {
                const valorConfig = filter.values.find(val => val.id === v)
                valConfig.push(valorConfig.url || valorConfig.name)
              } else {
                valConfig.push(v)
              }
            })
            filters2api += '/' + (filter.url || filter.name) + '-' + valConfig.join(',')
          }
        } else {
          storeUrl = (filters && filters[filter.id]) ? Object.keys(filters[filter.id]).sort().join(',') : ''
        }
      })
    }
    if (TypeConfig && (filters2api || storeUrl)) {
      const newPath = storeUrl ? route.path.replace(`/${TypeConfig.paths.prices_all}/`, `/${storeUrl.toLowerCase()}/`).slice(0, -1) + filters2api + '/' : route.path.slice(0, -1) + filters2api + '/'
      if (newPath !== route.path) {
        return redirect(newPath)
      }
    }
  }
}
