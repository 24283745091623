export const state = () => ({
  list: {}
})

export const mutations = {
  ADD (state, info) {
    if (!state.list[info.data]) {
      state.list[info.data] = {}
    }
    if (!state.list[info.data][info.id]) {
      state.list[info.data][info.id] = {}
    }
    if (!state.list[info.data][info.id][info.connotation]) {
      state.list[info.data][info.id][info.connotation] = {}
    }
    if (!state.list[info.data][info.id][info.connotation][info.filter]) {
      state.list[info.data][info.id][info.connotation][info.filter] = []
    }
    if (!state.list[info.data][info.id][info.connotation][info.filter][info.value]) {
      state.list[info.data][info.id][info.connotation][info.filter][info.value] = true
    }
  },
  REMOVE (state, info) {
    if (
      state.list[info.data] &&
      state.list[info.data][info.id][info.connotation] &&
      state.list[info.data][info.id][info.connotation][info.filter] &&
      state.list[info.data][info.id][info.connotation][info.filter][info.value]
    ) {
      delete state.list[info.data][info.id][info.connotation][info.filter][info.value]
      if (Object.keys(state.list[info.data][info.id][info.connotation][info.filter]).length <= 0) {
        delete state.list[info.data][info.id][info.connotation][info.filter]
      }
    }
  }
}

export const actions = {
  add ({ commit }, info) {
    const commitInfo = { data: info.data, id: info.id, connotation: info.connotation, filter: info.filter, value: info.value, filters: info.filters }
    commit('ADD', commitInfo)
  },
  remove ({ commit }, info) {
    const commitInfo = { data: info.data, id: info.id, connotation: info.connotation, filter: info.filter, value: info.value }
    commit('REMOVE', commitInfo)
  }
}
