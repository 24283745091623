//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    const data = {
      price: this.product.price.lower.custom
        ? this.product.price.lower.custom - 1
        : 0
    }
    for (const index in this.product.filters) {
      data[index] = []
    }
    return {
      showMessage: false,
      showSend: true,
      message: '',
      active: false,
      disable: false,
      alert: false,
      disabling: false,
      successMsg: '',
      color: 'danger',
      bell: require('@/assets/img/icons/icon-bell.svg'),
      bell_slash: require('@/assets/img/icons/icon-bell-slash.svg'),
      data
    }
  },
  async mounted () {
    this.alert = false
    if (this.$auth.loggedIn) {
      try {
        await this.$axios
          .get(
            this.$config.api +
              '/app/v1/users/alerts/' +
              this.product.type_id +
              '/' +
              this.product.content_id +
              '/',
            {
              headers: {
                Authorization: this.$auth.strategy.token.get()
              },
              data: {
                type: 'prices'
              }
            }
          )
          .then((resp) => {
            if (resp.status === 200 && resp.data.length > 0) {
              this.alert = true
            }
          })
      } catch (error) {
        this.alert = false
      }
    }
    if (this.$route.hash === '#alert') {
      this.$refs.alert.click()
    } else if (this.$route.hash === '#comparator') {
      this.$refs.comparator.click()
    } else {
      applyAuthAction.apply(this, [null, 'ProductPricesAlert'])
    }
  },
  methods: {
    async sendAlert () {
      this.showMessage = false
      this.showSend = true
      this.color = 'danger'
      const bodyFormData = new FormData()
      if (
        !isNaN(parseFloat(this.data.price)) &&
        parseFloat(this.data.price) > 0
      ) {
        bodyFormData.append(
          'data[price]',
          parseFloat(this.data.price).toFixed(2)
        )
      } else {
        this.showMessage = true
      }
      for (const index in this.product.filters) {
        if (this.data[index].length) {
          this.data[index].forEach((element) => {
            bodyFormData.append('data[' + index + '][]', element)
          })
        }
      }
      bodyFormData.append('type', 'prices')
      if (!this.showMessage) {
        try {
          await this.$axios
            .post(
              this.$config.api +
                '/app/v1/users/alerts/' +
                this.product.type_id +
                '/' +
                this.product.content_id +
                '/',
              bodyFormData,
              {
                headers: {
                  Authorization: this.$auth.strategy.token.get()
                }
              }
            )
            .then((resp) => {
              if (resp.status === 200) {
                this.showMessage = true
                this.showSend = false
                this.message = this.$t('ProductHeaderButtons.alert_received')
                this.color = 'success'
                this.alert = true
              } else {
                this.showMessage = true
                this.message = this.$t('ProductHeaderButtons.error_submitting')
              }
            })
        } catch (error) {
          this.showMessage = true
          this.message = this.$t(
            'ProductHeaderButtons.error_submitting'
          )
        }
      } else {
        this.message = this.$t('ProductHeaderButtons.error_submitting')
      }
    },
    cleanAlert () {
      this.message = ''
      this.showSend = true
      this.showMessage = false
      this.hideModal()
    },
    disableAlert () {
      this.disabling = false
      this.$bvModal.hide('bv-modal-confirm')
      try {
        this.$axios
          .delete(
            this.$config.api +
              '/app/v1/users/alerts/' +
              this.product.type_id +
              '/' +
              this.product.content_id +
              '/',
            {
              headers: {
                Authorization: this.$auth.strategy.token.get()
              },
              data: {
                _p: `${this.$config.id}`,
                type: 'prices'
              }
            }
          )
          .then((resp) => {
            if (resp.status === 200) {
              this.disabling = true
              this.alert = false
              this.successMsg = this.$t('ProductHeaderButtons.alert_deleted', {
                marca: this.product.brand.name,
                producto: this.product.name
              })
            }
          })
      } catch (error) {
        this.disabling = true
        this.successMsg = this.$t('ProductHeaderButtons.error_deleting_alert', {
          marca: this.product.brand.name,
          producto: this.product.name
        })
      }
    },
    onCheckLogin () {
      if (this.$auth.loggedIn) {
        this.showModal()
      } else {
        this.hideModal()
        openAuthRequired.apply(this, [
          null,
          {
            name: 'showModal'
          },
          'modal',
          'products',
          false,
          'ProductPricesAlert'
        ])
      }
    },
    showModal () {
      this.$bvModal.show('alert-price-modal')
    },
    hideModal () {
      this.$bvModal.hide('alert-price-modal')
    }
  }
}
