//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import * as textFunctions from '~/mixins/text.functions.js'

export default {
  mixins: [global, textFunctions],
  props: {
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    title: {
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: ''
    },
    w: {
      type: Number,
      default: 0
    },
    gender: {
      type: Array,
      default: null
    },
    org: {
      type: String,
      default: ''
    },
    preload: {
      type: Boolean,
      default: false
    },
    pos: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      lower: this.product?.price?.lower ?? 0,
      gtmData: {}
    }
  },
  beforeMount () {
    if (this.product.type !== 'AdvertisingProductsCard') {
      this.gtmData = {
        item_brand: this.product.brand,
        item_brand_id: this.product.brand_id,
        item_type_id: this.product.type_id,
        item_id: this.product.content_id,
        item_name: this.product.name,
        lower_price: this.product.price.lower,
        amp: this.$isAMP,
        pos: this.pos,
        org: this.org,
        link: this.setProductLink(this.product.path)
      }
    }
  },
  methods: {
    photo (producto, gender) {
      if (gender != null) {
        if (gender.includes('man') && !gender.includes('woman')) {
          return producto.photo_man !== ''
            ? producto.photo_man.src
            : producto.photo.src
              ? producto.photo.src
              : producto.photo
        }
        if (gender.includes('woman') && !gender.includes('man')) {
          return producto.photo_woman !== ''
            ? producto.photo_woman.src
            : producto.photo.src
              ? producto.photo.src
              : producto.photo
        }
        if (gender.includes('woman') && gender.includes('man')) {
          return producto.photo.src ? producto.photo.src : producto.photo
        }
        if (!gender.includes('woman') && !gender.includes('man')) {
          return producto.photo.src ? producto.photo.src : producto.photo
        }
      } else {
        return producto.photo.src ? producto.photo.src : producto.photo
      }
    }
  }
}
