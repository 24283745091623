//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  async asyncData ({ app, store, $axios, route, $config, $md5, error }) {
    // Get Zones data
    const ax = new AxiApi($config.api)
    let call = $config.static + '/json/zones/pages/zones.json'
    let zones
    try {
      zones = await $axios.get(call)
    } catch (err) {
      zones = {}
    }
    await ax.createComponentData(zones.data, store)

    // Get JSON data
    call = `${$config.static}/json/pages${route.path}index.json`
    const code = $md5(call)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', call)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const calculator = store.state.lists[code]

    // Metas and head
    const head = {
      title: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`
        },
        {
          hid: 'description',
          name: 'description',
          content: (calculator.metas.description !== '') ? `${calculator.metas.description}` : `${calculator.entrance}`
        },
        {
          property: 'og:url',
          content: `${$config.domain}${calculator.metas.canonical}`
        },
        {
          property: 'og:title',
          content: (calculator.metas.title !== '') ? `${calculator.metas.title}` : `${calculator.title}`
        },
        {
          property: 'og:description',
          content: (calculator.metas.description !== '') ? `${calculator.metas.description}` : `${calculator.entrance}`
        }
      ],
      link: [{
        rel: 'canonical',
        href: `${$config.domain}${calculator.metas.canonical}`
      }]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'calculator', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      head,
      calculator
    }
  },
  data () {
    return {
      projectData,
      intervals: [{
        type: 'interval',
        content: {
          distance: '',
          rhythm: '',
          time: ''
        }
      }],
      up: { src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), alt: 'Open', width: 12, heigth: 7 },
      down: { src: require('@/assets/img/icons/icon-chevron-down-primary.svg'), alt: 'Close', width: 12, heigth: 7 },
      distanceResult: '-',
      rhythmResult: '-',
      timeResult: '-',
      error: '',
      read: false
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    newInterval () {
      if (this.read === false) {
        this.intervals.push(
          {
            type: 'interval',
            content: {
              distance: '',
              rhythm: '',
              time: ''
            }
          }
        )
      }
      this.checkEmptyValues()
    },
    newGroup () {
      if (this.read === false) {
        this.intervals.push(
          {
            type: 'group',
            reps: 1,
            content: [
              {
                distance: '',
                rhythm: '',
                time: ''
              }
            ]
          }
        )
      }
      this.checkEmptyValues()
    },
    newGroupInterval (i) {
      if (this.read === false) {
        this.intervals[i].content.push({
          distance: '',
          rhythm: '',
          time: ''
        })
      }
      this.checkEmptyValues()
    },
    checkInterval (oldIndex, newIndex) {
      let valid = true
      if (this.intervals.length < 1) { valid = false }
      if (oldIndex === 0 && oldIndex > newIndex) { valid = false }
      if (oldIndex === this.intervals.length - 1 && oldIndex < newIndex) { valid = false }
      if (valid === true) { this.moveInterval(oldIndex, newIndex) }
    },
    moveInterval (oldIndex, newIndex) {
      const arr = this.intervals
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      this.intervals = arr // for testing
    },
    checkGroupInterval (intervalIndex, oldIndex, newIndex) {
      let valid = true
      if (this.intervals[intervalIndex].content.length < 1) { valid = false }
      if (oldIndex === 0 && oldIndex > newIndex) { valid = false }
      if (oldIndex === this.intervals[intervalIndex].content.length - 1 && oldIndex < newIndex) { valid = false }
      if (valid === true) { this.moveGroupInterval(intervalIndex, oldIndex, newIndex) }
    },
    moveGroupInterval (intervalIndex, oldIndex, newIndex) {
      const arr = this.intervals[intervalIndex].content
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      this.intervals[intervalIndex].content = arr
    },
    deleteInterval (i) {
      this.intervals.splice(i, 1)
      this.recalculate()
      this.checkEmptyValues()
    },
    deleteGroupInterval (i, g) {
      this.intervals[i].content.splice(g, 1)
      if (this.intervals[i].content.length === 0) {
        this.intervals.splice(i, 1)
      }
      this.recalculate()
      this.checkEmptyValues()
    },
    clearIntervals () {
      while (this.intervals.length > 0) { this.intervals.pop() }
      this.read = false
      this.distanceResult = '-'
      this.rhythmResult = '-'
      this.timeResult = '-'
      this.intervals.push(
        {
          type: 'interval',
          content: {
            distance: '',
            rhythm: '',
            time: ''
          }
        }
      )
    },
    seconds2Hour (seconds) {
      if (!isNaN(seconds)) {
        const hours = Math.floor(seconds / 3600)
        const remainingSeconds = seconds % 3600
        const minutes = Math.floor(remainingSeconds / 60)
        const calculatedSeconds = Math.floor(remainingSeconds % 60)
        return ((hours > 0) ? hours + ':' : '') + (((minutes < 10) && (hours > 0)) ? '0' + minutes : minutes) + ':' + ((calculatedSeconds < 10) ? '0' + calculatedSeconds : calculatedSeconds)
      } else { return false }
    },
    hour2Seconds (hour) {
      if (hour.match(/^([0-9]+(:([0-5][0-9]|[0-9]))?|[0-9]+:([0-5][0-9]|[0-9]):([0-5][0-9]|[0-9]))$/)) {
        const data = hour.split(':')
        if (data.length === 3) { return (parseInt(data[0]) * 3600) + (parseInt(data[1]) * 60) + parseInt(data[2]) } else if (data.length === 2) { return (parseInt(data[0]) * 60) + parseInt(data[1]) } else { return (parseInt(data[0]) * 60) }
      } else { return false }
    },
    calculate () {
      let distanceSum = 0
      let rhythmSum = 0
      let timeSum = 0
      let count = 0
      for (const interval of this.intervals) {
        this.error = ''
        count = 0
        if (interval.type === 'interval') {
          if (interval.content.distance !== '') {
            distanceSum = distanceSum + parseInt(interval.content.distance)
            count = count + 1
          } else if (interval.content.rhythm !== '' && interval.content.time !== '') {
            interval.content.distance = (this.hour2Seconds(interval.content.time) * 1000) / this.hour2Seconds(interval.content.rhythm)
            distanceSum = distanceSum + parseInt(interval.content.distance)
          }
          if (interval.content.rhythm !== '') {
            rhythmSum = rhythmSum + this.hour2Seconds(interval.content.rhythm)
            count = count + 1
          } else if (interval.content.distance !== '' && interval.content.time !== '') {
            interval.content.rhythm = this.seconds2Hour(this.hour2Seconds(interval.content.time) * 1000 / interval.content.distance)
            rhythmSum = rhythmSum + this.hour2Seconds(interval.content.rhythm)
            interval.content.rhythm = this.formatZero(interval.content.rhythm, 'rhythm')
          }
          if (interval.content.time !== '') {
            timeSum = timeSum + this.hour2Seconds(interval.content.time)
            count = count + 1
          } else if (interval.content.distance !== '' && interval.content.rhythm !== '') {
            interval.content.time = this.seconds2Hour((this.hour2Seconds(interval.content.rhythm) * interval.content.distance) / 1000)
            timeSum = timeSum + this.hour2Seconds(interval.content.time)
            interval.content.time = this.formatZero(interval.content.time, 'time')
          }
        } else {
          for (const group of interval.content) {
            count = 0
            if (group.distance !== '') {
              distanceSum = distanceSum + (group.distance * interval.reps)
              count = count + 1
            } else if (group.rhythm !== '' && group.time !== '') {
              group.distance = (this.hour2Seconds(group.time) * 1000) / this.hour2Seconds(group.rhythm)
              distanceSum = distanceSum + parseInt(group.distance)
            }
            if (group.rhythm !== '') {
              rhythmSum = rhythmSum + (this.hour2Seconds(group.rhythm) * interval.reps)
              count = count + 1
            } else if (group.distance !== '' && group.time !== '') {
              group.rhythm = this.seconds2Hour(this.hour2Seconds(group.time) * 1000 / group.distance)
              rhythmSum = rhythmSum + (this.hour2Seconds(group.rhythm) * interval.reps)
              group.rhythm = this.formatZero(group.rhythm, 'rhythm')
            }
            if (group.time !== '') {
              timeSum = timeSum + (this.hour2Seconds(group.time) * interval.reps)
              count = count + 1
            } else if (group.distance !== '' && group.rhythm !== '') {
              group.time = this.seconds2Hour((this.hour2Seconds(group.rhythm) * group.distance) / 1000)
              timeSum = timeSum + (this.hour2Seconds(group.time) * interval.reps)
              group.time = this.formatZero(group.time, 'time')
            }
          }
        }
        if (count < 2) {
          this.error = this.$t('calculator.error_missing')
        }
        if (count === 3) {
          this.error = this.$t('calculator.error_empty')
        }
      }
      if (this.error === '' && this.checkInvalidValues() === false) {
        this.distanceResult = distanceSum
        this.rhythmResult = this.seconds2Hour(rhythmSum / this.getIntervalCount())
        this.timeResult = this.seconds2Hour(timeSum)
        this.read = true
      }
      this.checkEmptyValues()
      this.checkInvalidValues()
    },
    recalculate () {
      this.distanceResult = 0
      this.timeResult = 0
      this.rhythmResult = 0
      for (const interval of this.intervals) {
        if (interval.type === 'interval') {
          this.distanceResult = this.distanceResult + parseInt(interval.content.distance)
          this.rhythmResult = this.rhythmResult + this.hour2Seconds(interval.content.rhythm)
          this.timeResult = this.timeResult + this.hour2Seconds(interval.content.time)
        } else {
          for (const group of interval.content) {
            this.distanceResult = this.distanceResult + (parseInt(group.distance) * interval.reps)
            this.rhythmResult = this.rhythmResult + (this.hour2Seconds(group.rhythm) * interval.reps)
            this.timeResult = this.timeResult + (this.hour2Seconds(group.time) * interval.reps)
          }
        }
      }
      this.rhythmResult = this.rhythmResult / this.getIntervalCount()
      this.rhythmResult = this.seconds2Hour(this.rhythmResult)
      this.timeResult = this.seconds2Hour(this.timeResult)
      if (this.intervals.length === 0) {
        this.read = false
        this.intervals.push(
          {
            type: 'interval',
            content: {
              distance: '',
              rhythm: '',
              time: ''
            }
          }
        )
        this.distanceResult = '-'
        this.rhythmResult = '-'
        this.timeResult = '-'
      }
    },
    modify () {
      this.read = false
    },
    formatZero (time, type) {
      if (type === 'rhythm') {
        while (time.length < 5) { time = '0' + time }
      } else {
        while (time.length < 8) {
          if (time.length === 5) { time = ':' + time }
          time = '0' + time
        }
      }
      return time
    },
    getIntervalCount () {
      let count = 0
      for (const interval of this.intervals) {
        if (interval.type === 'group') {
          for (let i = 0; i < interval.content.length; i++) {
            count = count + 1
          }
        } else { count = count + 1 }
      }
      return count
    },
    checkEmptyValues () {
      if (!this.distanceResult) { this.distanceResult = '-' }
      if (this.rhythmResult === '0:00') { this.rhythmResult = '-' }
      if (this.timeResult === '0:00') { this.timeResult = '-' }
    },
    checkInvalidValues () {
      let valid = false
      for (const interval of this.intervals) {
        if (interval.type === 'group') {
          for (const group of interval.content) {
            if (group.rhythm.length > 5 || group.time.length > 8) {
              this.error = this.$t('calculator.error_not_valid')
              group.distance = '-'
              group.rhythm = '-'
              group.time = '-'
              valid = true
              break
            }
          }
        } else if (interval.content.rhythm.length > 5 || interval.content.time.length > 8) {
          this.error = this.$t('calculator.error_not_valid')
          interval.content.distance = '-'
          interval.content.rhythm = '-'
          interval.content.time = '-'
          valid = true
          break
        }
      }
      return valid
    }
  }
}
