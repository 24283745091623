export const state = () => ({
  list: [],
  products: {}
})

export const mutations = {
  SET_LIST (state, list) {
    state.list = list
    sessionStorage.comparator = JSON.stringify(state.list)
  },
  ADD (state, product) {
    state.list = state.list.filter(item => item.content_id !== product.content_id)
    state.list.push(product)
    state.list = state.list.slice(-2)
    sessionStorage.comparator = JSON.stringify(state.list)
  },
  REMOVE (state, product) {
    state.list = state.list.filter(item => item.content_id !== product.content_id)
    sessionStorage.comparator = JSON.stringify(state.list)
  },
  SET_PRODUCT (state, { key, value }) {
    state.products[key] = value
  }
}

export const actions = {
  init ({ commit }) {
    commit('SET_LIST', sessionStorage.comparator ? JSON.parse(sessionStorage.comparator) : [])
  },
  async add ({ commit, state, rootState }, product) {
    const query = `${rootState.config.static}/${product.path}`

    const code = this.$md5(query)

    if (!state.products[code]) {
      try {
        const response = await this.$axios.get(query)
        if (response.status === 200) {
          commit('SET_PRODUCT', { key: code, value: response.data })
        }
      } catch (error) {
      }
    }

    commit('ADD', state.products[code])
  },
  remove ({ commit }, product) {
    commit('REMOVE', product)
  }
}
