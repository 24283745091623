//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import global from '~/mixins/global.js'
import AxiApi from '~/lib/axi'
import project from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'default.amp' : 'default'
  },
  props: {
    tipo: {
      type: String,
      default: ''
    }
  },
  async asyncData ({ params, route, app, store, error, $axios, $config, $md5 }) {
    try {
      project.contents.races.all.filters =
        project.contents.races.all.filters.map((filter) => {
          if (['province', 'region', 'country'].includes(filter.id)) {
            filter.values = filter.values.map((value) => {
              value.url = value.url || value.name
              value.name =
                value.name.charAt(0).toUpperCase() + value.name.slice(1)
              return value
            })
          }
          return filter
        })

      // Params & Props
      const lastIndex = params.pathMatch.lastIndexOf('/')
      const routeProps = route.matched[0].props.default
      const tipo = routeProps.tipo
      let pathMatch = ''
      if (params.pathMatch.includes('index.html')) {
        pathMatch = params.pathMatch.substring(0, lastIndex)
      } else {
        pathMatch = params.pathMatch
      }
      // Zones & Heritages
      const ax = new AxiApi($config.api)
      let zones
      try {
        zones = await $axios.get(
          `${$config.static}/json/races/${pathMatch}/zones.json`
        )
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
      await ax.createComponentData(zones.data, store)

      // Get Race data
      let query = `${$config.static}/json/races/${pathMatch}/index.json`
      let code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        try {
          await store.dispatch('loadListsError', query)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      const race = store.state.lists[code]
      const related = race.related.filter((e) => Object.keys(e).length > 0)

      let Text = ''
      if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
        Text = route.query.q
      }

      // get race filters
      query = `${$config.api}/app/v1/races/list?_p=${$config.id}`
      code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }
      const raceList = store.state.lists[code]

      const puntuaciones = Object.values(race.score)
      const puntuacionesTotal = puntuaciones.reduce(
        (t, value) => parseInt(t) + parseInt(value),
        0
      )
      race.average_score = parseFloat(
        parseInt(puntuacionesTotal) / puntuaciones.length
      ).toFixed(1)

      // Breadcrumb
      let breadcrumb = []
      breadcrumb = breadcrumb.concat(routeProps.breadcrumb)
      if (race.province !== '') {
        const provinceF = project.contents.races.all.filters.find(
          (f) => f.id === 'province'
        )
        const provinceFV = provinceF?.values.find(
          (v) => v.id === race.province
        )
        if (provinceFV) {
          breadcrumb.push({
            text: provinceFV.name,
            href: `/${project.contents.races.all.paths.home}/${
              project.contents.races.all.paths.races
            }/${provinceFV.url || provinceFV.name}/`
          })
        }
      }
      if (race.region !== '') {
        const regionF = project.contents.races.all.filters.find(
          (f) => f.id === 'region'
        )
        const regionFV = regionF?.values.find((v) => v.id === race.region)
        if (regionFV) {
          breadcrumb.push({
            text: regionFV.name,
            href: `/${project.contents.races.all.paths.home}/${
              project.contents.races.all.paths.races
            }/${regionFV.url || regionFV.name}/`
          })
        }
      }
      if (race.date) {
        const date = new Date(
          race.date.replace(/^(\d{2})-(\d{2})-(\d{4})$/g, '$3/$2/$1')
        )
        const year = date.getFullYear()
        const month = date
          .toLocaleString('en-US', { month: 'long' })
          .toLowerCase()
        const yearF = project.contents.races.all.filters.find(
          (f) => f.id === 'year'
        )
        const yearFV = yearF.values.find((v) => v.id === String(year))
        if (yearFV) {
          breadcrumb.push({
            text: yearFV.name,
            href: `/${project.contents.races.all.paths.home}/${
              project.contents.races.all.paths.races
            }/${yearF.url || yearF.name}-${yearFV.url || yearFV.name}/`
          })
        }
        const monthF = project.contents.races.all.filters.find(
          (f) => f.id === 'month'
        )
        const monthFV = monthF.values.find((v) => v.id === month)
        if (monthFV) {
          breadcrumb.push({
            text: monthFV.name,
            href: `/${project.contents.races.all.paths.home}/${
              project.contents.races.all.paths.races
            }/${monthFV.url || monthFV.name}/`
          })
        }
      }
      if (race.title !== '' && race.path !== '') {
        breadcrumb.push({
          text: race.title
        })
      }
      // Structured Data
      const splitedDate = race.date.split('-')
      const splitedTime = race.time.split(':')
      const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: race.title,
        startDate: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}T${splitedTime[0]}:${splitedTime[1]}:${splitedTime[2]}`,
        endDate: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}T${splitedTime[0]}:${splitedTime[1]}:${splitedTime[2]}`,
        eventAttendanceMode: race.surface === 'virtual' ? 'online' : 'offline',
        url: `${$config.domain}` + route.fullPath,
        description: race.description.replace(/<\/?.*?>/g, ''),
        location: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressLocality: race.place,
            addressRegion: race.province,
            addressCountry: {
              '@type': 'Country',
              name: race.country
            }
          },
          name: race.place
        },
        organizer: {
          '@type': 'Organization',
          name: race.organizer
        },
        image: []
      }
      if (race.poster?.src) {
        structuredData.image.push(race.poster.src)
      }
      if (race.logo?.src) {
        structuredData.image.push(race.logo.src)
      }
      if (race.route?.src) {
        structuredData.image.push(race.route.src)
      }
      if (race.score) {
        structuredData.review = []
        const scores = Object.entries(race.score)
        scores.forEach(([id, score]) => {
          if (score > 0) {
            structuredData.review.push({
              '@type': 'Review',
              author: {
                '@type': 'Person',
                name: 'Runnea'
              },
              reviewRating: {
                '@type': 'Rating',
                bestRating: '10',
                ratingValue: score,
                worstRating: '1'
              }
            })
          }
        })
        if (!isNaN(race.average_score)) {
          structuredData.AggregateRating = {
            '@type': 'AggregateRating',
            bestRating: '10',
            worstRating: '1',
            ratingValue: parseInt(race.average_score),
            ratingCount: scores.length
          }
        }
      }

      // Metas & Head
      const head = {
        title: `${tipo !== '' ? `${app.i18n.t('race_page.' + tipo)} ` : ''}${
          race.metas.title !== ''
            ? race.metas.title
            : app.i18n.t('race_page.meta_title').replace(/{seo}/g, race.title)
        } - ${app.i18n.t('race_page.races_title')} | Runnea`,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: `${
              tipo !== '' ? `${app.i18n.t('race_page.' + tipo)} ` : ''
            }${
              race.metas.title !== ''
                ? race.metas.title
                : app.i18n
                    .t('race_page.meta_title')
                    .replace(/{seo}/g, race.title)
            } - ${app.i18n.t('race_page.races_title')} | Runnea`
          },
          {
            hid: 'description',
            name: 'description',
            content:
              race.metas.description !== ''
                ? `${race.metas.description}`
                : app.i18n
                  .t('race_page.meta_description')
                  .replace(/{seo}/g, race.title)
          },
          {
            property: 'og:url',
            content: `${$config.domain}${route.path}`
          },
          {
            property: 'og:title',
            content: `${
              tipo !== '' ? `${app.i18n.t('race_page.' + tipo)} ` : ''
            }${
              race.metas.title !== ''
                ? race.metas.title
                : app.i18n
                    .t('race_page.meta_title')
                    .replace(/{seo}/g, race.title)
            } - ${app.i18n.t('race_page.races_title')} | Runnea`
          },
          {
            property: 'og:description',
            content:
              race.metas.description !== ''
                ? `${race.metas.description}`
                : app.i18n
                  .t('race_page.meta_description')
                  .replace(/{seo}/g, race.title)
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: `${$config.locale}`
          }
        ],
        link: [
          {
            rel: 'canonical',
            href: `${$config.domain}${route.path}`
          }
        ],
        script: [{ type: 'application/ld+json', json: structuredData }]
      }

      if (Object.keys(route.query).length > 0) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      } else if (tipo === 'verify') {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        })
      }

      if (race.poster && race.poster.src !== '') {
        head.meta.push({
          property: 'og:image',
          content: race.poster.src
        })
      }

      // extraModules
      const extraModulesTop = []
      if (!race.verified) {
        extraModulesTop.push({
          template: 'ModuleFeatured',
          data: {
            title: app.i18n.t('race_page.fill_form'),
            antetitle: app.i18n.t('race_page.organizer'),
            link: {
              title: app.i18n.t('race_page.verify_race'),
              url: `/${project.contents.races.all.paths.home}/${pathMatch}/${project.contents.races.all.paths.verify}.html`,
              target: false,
              nofollow: false
            }
          },
          elements: {},
          generic: {
            header: {},
            footer: []
          }
        })
      }

      const zonesContent = ax.get()

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'race_page', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0,
          extraModulesTop: extraModulesTop.length ? 1 : 0
        })
      }

      return {
        race,
        races: raceList,
        breadcrumb,
        zones: zonesContent,
        head,
        projectRaces: project.contents.races.all,
        Text,
        related,
        extraModulesTop
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      project,
      breadcrumb: []
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
