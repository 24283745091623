export const state = () => ({
  necessary: true,
  functional: false,
  analytics: false,
  marketing: false
})

export const mutations = {
  SET (state, { category, value }) {
    state[category] = value
  }
}

export const actions = {
  set ({ commit }, options) {
    commit('SET', options)
  }
}
