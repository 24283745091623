export const state = () => ({
  consent: false
})

export const mutations = {
  CONSENT (state) {
    state.consent = true
  }
}

export const actions = {
  consent ({ commit }) {
    commit('CONSENT')
  }
}
