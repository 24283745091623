//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookieConsentCustom from '~/plugins/cookieconsent'
import project from '~/static/config/project.json'
import { getAuthLocation, delAuth } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'

export default {
  name: 'LytClnCls',
  head () {
    const metas = { meta: [] }

    if (project.meta?.snipped) {
      metas.meta.push({
        name: 'robots',
        content:
          'max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      })
    }

    if (project.meta?.noindex) {
      metas.meta.push({
        name: 'robots',
        content: 'noindex'
      })
    }

    return metas
  },
  mounted () {
    if (project.plugins?.cookieconsent) {
      let ccAccept = false
      cookieConsentCustom(
        {
          onAccept: (cookie) => {
            ccAccept = true
            let ccAnalytics = false
            if (cookie.categories.includes('analytics')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAnalytics' })
              ccAnalytics = true
            }
            let ccMarketing = false
            if (cookie.categories.includes('marketing')) {
              this.$gtm && this.$gtm.push({ event: 'ConsentGrantedAd' })
              window.googletag?.cmd.push(function () {
                window.googletag.pubads().setPrivacySettings({
                  limitedAds: false,
                  nonPersonalizedAds: false,
                  restrictDataProcessing: false
                })
              })
              ccMarketing = true
            }
            if (window.marfeel) {
              window.marfeel.cmd.push(['consent', function (consent) { consent.setConsentFeatures([{ feature: 'analytics_storage', consent: ccAnalytics }, { feature: 'personalization', consent: ccMarketing }]) }])
            }

            customPageView.apply(this, [document?.title, false, false])
            this.$store.dispatch('gtm/consent')
          }
        },
        this.$t('consent_cookie')
      )
      if (window.marfeel && !ccAccept) {
        window.marfeel.cmd.push(['consent', function (consent) { consent.setCMPLoaded() }])
      }
    }
  },
  methods: {
    close () {
      let location = {
        path: '/'
      }
      if (this.$route.query._ai) {
        const authLocation = getAuthLocation(this.$route.query._ai)
        if (authLocation) {
          location = authLocation
        }
      }
      delAuth()
      this.$router.push(location)
    }
  }
}
