export const state = () => ({
  id: null,
  location: null,
  action: null
})

export const mutations = {
  SET_ID (state, id) {
    state.id = id
  },
  SET_LOCATION (state, location) {
    state.location = location
  },
  DELETE_LOCATION (state) {
    state.location = null
  },
  SET_ACTION (state, action) {
    state.action = action
  },
  DELETE_ACTION (state) {
    state.action = null
  },
  RESET (state) {
    state = {
      id: null,
      location: null,
      action: null
    }
  }
}

export const actions = {
  setId ({ commit }, id) {
    commit('SET_ID', id)
  },
  setLocation ({ commit }, location) {
    commit('SET_LOCATION', location)
  },
  deleteLocation ({ commit }) {
    commit('DELETE_LOCATION')
  },
  setAction ({ commit }, action) {
    commit('SET_ACTION', action)
  },
  deleteAction ({ commit }) {
    commit('DELETE_ACTION')
  },
  reset ({ commit }) {
    commit('RESET')
  }
}
