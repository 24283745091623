//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { applyAuthAction } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'
import * as modalFunctions from '~/mixins/modal.functions.js'
import { countryData } from '~/lib/countries'
export default {
  async asyncData ({ app, params, route, store, error, $axios, $config }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    let zoneUrl = $config.static + '/json/pages/portada.json'
    let path = params.pathMatch
    if (path !== '') {
      if (params.pathMatch.charAt(params.pathMatch.length - 1) !== '/') {
        path = params.pathMatch.match('([^.]+)')[1]
        zoneUrl = $config.static + '/json/pages/' + path + '.json'
      } else {
        zoneUrl = $config.static + '/json/pages/' + path + 'index.json'
      }
    }
    let landingData
    try {
      landingData = await $axios.get(zoneUrl)
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(landingData.data, store, countryData(store.state.countries.countryProject))
    // Metas & Head
    const canonical = landingData.data.metas.canonical !== ''
      ? landingData.data.metas.canonical.match(/^https?:\/\//)
        ? landingData.data.metas.canonical
        : `${$config.domain}${landingData.data.metas.canonical}`
      : false
    const head = {
      title: landingData.data.metas.title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: landingData.data.metas.title
        },
        {
          hid: 'description',
          name: 'description',
          content: landingData.data.metas.description
        }
      ],
      link: []
    }
    if (landingData.data.metas.robots !== '') {
      head.link.push({
        hid: 'robots',
        name: 'robots',
        content: landingData.data.metas.robots
      })
    }
    if (canonical) {
      head.link.push({
        rel: 'canonical',
        href: canonical
      })
    }
    const zones = await ax.get()
    if (zones.GlobalData.zones.ABC_1) {
      zones.GlobalData.zones.ABC_1.forEach((e) => {
        if (Array.isArray(e.content.info) &&
                    e.content.info.length > 0 &&
                    e.content.info[0].photo) {
          head.link.push({
            rel: 'preload',
            href: e.content.info[0].photo.src,
            as: 'image'
          })
        }
      })
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path)
    }

    return {
      head,
      zones,
      fluid: landingData.data.fluid === '1'
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
    applyAuthAction.apply(this)
  },
  methods: {
    modalMsg (title, msg) {
      modalFunctions.msg.apply(this, [title, msg])
    }
  }
}
