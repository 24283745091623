//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import project from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ params, route, store, app, $axios, $md5, error, $config }) {
    const routeProps = route.matched[0].props.default
    const productType = Object.values(project.contents.product_types).find(
      p => p.id === routeProps.typeId
    )

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/tags/zones.json'
    const zones = await $axios.get(call)

    // Get Tag JSON data
    const queryJSON = `${$config.static}/json/tags/${params.pathMatch}/index.json`
    const codeJSON = $md5(queryJSON)
    if (
      typeof store.state.lists[codeJSON] === 'undefined' ||
      Object.keys(store.state.lists[codeJSON]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', queryJSON)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const tag = store.state.lists[codeJSON]

    zones.data.zones.Text = tag.text || []

    await ax.createComponentData(zones.data, store)

    const zonesContent = ax.get()

    // Get API data
    let page = 1
    if (params.pag && params.pag > 0) {
      page = params.pag
    }
    const order = '&order=date'
    /* if (route.query.order) {
      const val = route.query.order.split('_')
      order = `&order=${val[0]}&direction=${val[1]}`
    } */
    const query = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${routeProps.typeId}&filters=&text=${tag.descriptores.join(' ')}&page=${page}${order}`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const itemList = store.state.lists[code]

    // Metas & Head
    const head = {
      title: `${tag.title} | Runnea`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${tag.title} | Runnea`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${tag.description}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path}`
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'tag_page', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      head,
      tag,
      productType,
      itemList,
      order,
      zones: zonesContent
    }
  },
  data () {
    return {
      // imagenes
      fechaabajogris: { width: 10, height: 24, class: 'icon', alt: 'order', src: require('@/assets/img/icons/icon-chevron-down-grey.svg') }
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    onChange (val) {
      window.location =
        window.location.pathname + (val !== 'date_desc' ? '?order=' + val : '')
    },
    parseText (text) {
      return text.replace(/\n/g, '<br/>')
    }
  }
}
