//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LytSmp',
  data () {
    return {
      header: {
        src: require('@/assets/img/logo-header-runnea.svg'),
        alt: 'Logo runnea',
        width: 143,
        heigth: 25
      }
    }
  },
  methods: {
    showBackButton () {
      // TODO: [MP2020-1027] Implementar esta función: Tiene que devolver true o false si queremos que se muestre el botón
      // return this.$route.path === '/search'
      return true
    }
  }
}
