//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import global from '~/mixins/global.js'
export default {
  mixins: [global],
  data () {
    return {
      races: null
    }
  },
  mounted () {
    this.loadBanner()
  },
  methods: {
    async loadBanner () {
      try {
        await axios.get('https://contenidos.runnea.academy/includes/datos/virtual-races.json').then((races) => {
          this.races = races.data
        })
      } catch (error) {}
    }
  }
}
