//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import global from '~/mixins/global.js'
import project from '~/static/config/project.json'
import { countryLocation } from '~/lib/countries'

export default {
  mixins: [global],
  data () {
    return {
      title: '',
      descrip: '',
      btnText: '',
      paisAcceso: '',
      host: '',
      ipOrigen: '',
      redireccinesRealizadas: [],
      redirectTo: '',
      registrarSalida: false
    }
  },
  mounted () {
    this.origen = Object.values(project.domains.www)[1]
    const tmp = localStorage.getItem('geoIp') || ''
    if (tmp === '') this.redireccinesRealizadas = []
    else this.redireccinesRealizadas = tmp.split('--')
    this.checkLocations()
  },
  methods: {
    showModal () {
      this.$refs['modal-geo-user'].show()
    },
    hideModal () {
      this.$refs['modal-geo-user'].hide()
      if (this.registrarSalida) {
        this.cancel()
      }
    },
    cancel () {
      this.sentDataToGTM({ modal_action: 'CloseModal', modal_label: `close-modal-${this.paisAcceso}`,modal_value: 1 })
    },
    sentDataToGTM (data) {
      this.$gtm && this.$gtm.push({ event: 'LocationRedirect', ...data })
    },
    async checkLocations () {
      try {
        this.host = Object.values(project.domains.www)[1]
        const tmp = localStorage.getItem('geoIp') || ''
        if (tmp === '') this.redireccinesRealizadas = []
        else this.redireccinesRealizadas = tmp.split('--')
        project.redirecByGeoIp.forEach(async (f) => {
          if (f.domain === this.host) {
            await countryLocation.then((resp) => {
              this.paisAcceso = resp.country
              this.ipOrigen = resp.ip
              if (f.country.includes(resp.country)) {
                let isRedirect = false
                if (this.redireccinesRealizadas.length > 0) {
                  this.redireccinesRealizadas.forEach((r) => {
                    const opt = r.split(',')
                    if (opt[0] === this.paisAcceso && opt[1] === this.host) {
                      isRedirect = true
                    }
                  })
                }
                if (!isRedirect) {
                  this.redirectTo = f.redirect
                  this.descrip = f.msg
                  this.btnText = f.btn
                  this.sentDataToGTM({ modal_action: 'ViewModal', modal_label: `view-modal-${this.paisAcceso}`, modal_value: 1 })
                  this.showModal()
                  this.registrarSalida = true
                }
              }
            })
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    proceder () {
      this.redireccinesRealizadas.push(`${this.paisAcceso},${this.host},${this.redirectTo}`)
      localStorage.setItem('geoIp', this.redireccinesRealizadas.join('--'))
      this.sentDataToGTM({ modal_action: 'ClickModal', modal_label: `click-modal-${this.paisAcceso}`, modal_value: 1 })
      this.registrarSalida = false
      this.hideModal()
      this.$router.push(this.redirectTo)
    }
  }
}
