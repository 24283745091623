//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    comments: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  methods: {
    parseText (text) {
      return text.replace(/\n/g, '<br/>')
    }
  }
}
