//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import * as formFunctions from '~/mixins/form.functions.js'
import { getAuthSource, setAuthNewsletter, authData } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'

export default {
  layout: 'clean.close',
  async asyncData ({ app, $axios, store, $config }) {
    const countries = [
      { value: '', text: app.i18n.t('user_page.select'), disabled: true }
    ]

    const rspCountries = await $axios.get('https://static.runnea.com/json/countries.json')

    if (rspCountries?.data) {
      rspCountries.data.forEach((country) => {
        countries.push({ value: country.code, text: country.name })
      })
    }

    const brands = []

    store.dispatch('loadBrands').then(() => {
      store.state.brands.forEach((brand) => {
        brands.push({ value: brand.content_id, text: brand.name })
      })

      brands.sort((a, b) => {
        const nameA = a.text.toUpperCase()
        const nameB = b.text.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      brands.unshift({ value: '', text: app.i18n.t('user_page.select'), disabled: true })
    })

    const sizes = [
      { value: '', text: app.i18n.t('user_page.select'), disabled: true }
    ]

    const rspFilters = await $axios.get(`${$config.api}/app/v1/products/filters?_p=${$config.id}&products_type=1`)
    if (rspFilters.data.filters?.talla?.order) {
      rspFilters.data.filters.talla.order.forEach((size) => {
        sizes.push({ value: size, text: size })
      })
    }

    return {
      countries,
      brands,
      sizes
    }
  },
  data () {
    const head = {
      title: 'Runnea - Auth Data',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    const signinData = {
      name: {
        value: this.$auth.user?.name || '',
        validate: () => this.isRequired('name')
      },
      sex: {
        value: this.$auth.user?.sex || '',
        validate: () => this.isRequired('sex')
      },
      country: {
        value: this.$auth.user?.country || projectData.country,
        validate: () => this.isRequired('country')
      },
      postalCode: {
        value: this.$auth.user?.postal_code || '',
        validate: () => ((this.signinData.country !== 'ES') || this.isRequired('postalCode')) && this.isPostalCode()
      },
      brand: {
        value: this.$auth.user?.brand || '',
        validate: () => this.isRequired('brand')
      },
      size: {
        value: this.$auth.user?.size || '',
        validate: () => this.isRequired('size')
      }
    }

    return {
      projectData,
      formValidated: false,
      authData: this.$auth.user,
      signinData,
      feedback: {
        valid: '',
        invalid: ''
      },
      sexos: [
        { value: '', text: this.$t('user_page.select'), disabled: true },
        { value: 'man', text: this.$t('register.man') },
        { value: 'woman', text: this.$t('register.woman') }
      ],
      authId: this.$route.query._ai,
      authToken: this.$auth.strategy.token.get(),
      authDataMandatory: authData(this.$auth.user),
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (!this.$auth.loggedIn) {
      this.$router.push({
        path: '/auth/login',
        query: {
          _ai: this.authId
        }
      })
    } else {
      if (!this.authDataMandatory) {
        this.$auth.logout()
      }
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.signinData[field]?.validate || this.signinData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.signinData)
    },
    isRequired (field, value = '') {
      return (this.signinData[field].value !== value)
    },
    isPostalCode () {
      return !this.signinData.postalCode.value || formFunctions.isValidPostalCode(this.signinData.postalCode.value)
    },
    async update () {
      this.formValidated = true
      if (this.validateForm()) {
        const bodyFormData = new URLSearchParams()
        bodyFormData.append('_p', this.$config.id)
        if (!this.authData.name) {
          bodyFormData.append('name', this.signinData.name.value)
        }
        if (!this.authData.sex) {
          bodyFormData.append('sex', this.signinData.sex.value)
        }
        if (!this.authData.country) {
          bodyFormData.append('country', this.signinData.country.value)
        }
        if (!this.authData.postalCode) {
          bodyFormData.append('postal_code', this.signinData.postalCode.value)
        }
        if (!this.authData.brand) {
          bodyFormData.append('brand', this.signinData.brand.value)
        }
        if (!this.authData.size) {
          bodyFormData.append('size', this.signinData.size.value)
        }
        const trigger = getAuthSource(this.authId)
        if (trigger) {
          bodyFormData.append('trigger', trigger)
        }
        await this.$axios.put(this.$config.api + '/app/v1/users/data', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: this.authToken
          }
        }).then((resp) => {
          if (resp.status === 200) {
            setAuthNewsletter(this.authId, true)
            if (this.$auth.loggedIn) {
              this.$router.push({
                path: '/auth/redirect',
                query: {
                  _ai: this.authId
                }
              })
            } else {
              this.$auth.loginWith('token', {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: this.authToken
                }
              }).then((resp) => {
                if (resp.status === 200) {
                  this.$router.push({
                    path: '/auth/redirect',
                    query: {
                      _ai: this.authId
                    }
                  })
                } else {
                  this.feedback.invalid = resp.statusText
                }
              }).catch(() => {
                this.feedback.invalid = this.$t('UserAuthForm.wrong_password')
              })
            }
          } else {
            this.feedback.invalid = resp.statusText
          }
        })
      }
    }
  }
}
