//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    }
  },
  methods: {
    getUrl () {
      return ('/' + this.pid + '/' + this.path + '/').replace(/\/+/g, '/')
    }

  }
}
