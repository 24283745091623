//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import global from "~/mixins/global.js";
import AxiApi from "~/lib/axi";
import project from '~/static/config/project.json'
import * as cssFunctions from '~/mixins/css.functions.js'
export default {
  layout: "default",
  mixins: [global],
  async asyncData({ params, route, app, store, error, $axios, $config, $md5 }) {
    // Zones & Heritages
    const ax = new AxiApi($config.api);
    const call = $config.static + "/json/zones/brands/zones.json";
    const zones = await $axios.get(call);
    await ax.createComponentData(zones.data, store);

      if(project.id !== 1) {
        error({ statusCode: 404, message: 'Page not found' })
      }
      // Structured Data
      const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: [
      ],
      itemListOrder: 'https://schema.org/ItemListUnordered',
      name: 'Running & Music',
    }

    // Metas & Head
    let pageTitle = 'Running & Music'
    let metaTitle = 'Running & Music'
    let metaDescription = 'Descubre una nueva manera de disfrutar del running con nuestras sesiones con música y guiadas por los mejores entrenadores'

    const head = {
      title: `${metaTitle} | Runnea`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${metaTitle} | Runnea`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${metaDescription}`
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${$config.domain}${route.path}`
        }
      ],
      script: [
        { type: 'application/ld+json', json: structuredData }
      ]
    }

    // Header
    const zonesContent = ax.get();

    const header = {
      h1: pageTitle
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, "audios_list", {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0,
      });
    }
    return {
      zones: zonesContent,
      head,
      header,
    };
  },
  data() {
    return {
      audioList: null,
    };
  },
  head () {
    return this.head
  },
  mounted() {
    const expande = document.querySelectorAll("details");
    if (document.documentElement.clientWidth > 767) {
      for (var i = 0; i < expande.length; i++) {
        expande[i].setAttribute("open", "");
      }
    }
    this.loadAudios();
  },
  methods: {
    async loadAudios() {
      try {
        await axios
          .get("https://contenidos.runnea.academy/audios/sesiones/index.json")
          .then((audios) => {
            this.audioList = audios.data;
          });
      } catch (error) {}
    },
  },
};
