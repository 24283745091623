//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import global from '~/mixins/global.js'
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  props: {
    sections: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    currentSection: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      selectedSection: this.currentSection?.id || false,
      selectedProduct: false,
      searchText: '',
      // Imagenes
      down: { alt: 'close', src: require('@/assets/img/icons/icon-chevron-down-grey.svg'), width: 12, heigth: 7 },
      up: { alt: 'open', src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), width: 12, heigth: 7 }
    }
  },
  methods: {
    loadProducts () {
      if (!this.$store.state.productsLoaded) {
        this.$store.dispatch('loadProducts')
      }
    },
    selectProduct (product) {
      this.selectedProduct = product
    },
    search4Questions () {
      const qryStrObj = this.$route.query
      let sectionPath = this.currentSection.path
      let productPath = ''
      if (this.selectedSection) {
        sectionPath = this.sections.find(s => s.id === this.selectedSection)?.path ?? sectionPath
      }
      if (this.selectedProduct) {
        productPath = this.$txt2system(`${this.selectedProduct.brand} ${this.selectedProduct.title} ${this.selectedProduct.content_id}`)
      }
      if (this.searchText !== '') {
        qryStrObj.q = this.searchText
        if(sectionPath){
          window.location.href =  this.$router.resolve({ path: `${sectionPath}/${productPath}`, query: qryStrObj }).href 
        }
       else{
        window.location.href =   this.$router.resolve({ query: qryStrObj }).href
       }       
      } else {
        if(sectionPath){
          this.$router.push(`${sectionPath}/${productPath}/`) 
        }
      }
    }
  }
}
