//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import * as formFunctions from '~/mixins/form.functions.js'
import { getAuthSource, setAuthNewsletter } from '~/lib/auth'
import { customPageView } from '~/lib/gtm'
import global from '~/mixins/global.js'

export default {
  mixins: [global],
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  layout: 'clean.close',
  async asyncData ({ app, $axios, store, $config }) {
    const countries = [
      { value: '', text: app.i18n.t('user_page.select'), disabled: true }
    ]

    const rspCountries = await $axios.get('https://static.runnea.com/json/countries.json')

    if (rspCountries?.data) {
      rspCountries.data.forEach((country) => {
        countries.push({ value: country.code, text: country.name })
      })
    }

    const brands = []

    store.dispatch('loadBrands').then(() => {
      store.state.brands.forEach((brand) => {
        brands.push({ value: brand.content_id, text: brand.name })
      })

      brands.sort((a, b) => {
        const nameA = a.text.toUpperCase()
        const nameB = b.text.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      brands.unshift({ value: '', text: app.i18n.t('user_page.select'), disabled: true })
    })

    const sizes = [
      { value: '', text: app.i18n.t('user_page.select'), disabled: true }
    ]

    const rspFilters = await $axios.get(`${$config.api}/app/v1/products/filters?_p=${$config.id}&products_type=1`)
    if (rspFilters.data.filters?.talla?.order) {
      rspFilters.data.filters.talla.order.forEach((size) => {
        sizes.push({ value: size, text: size })
      })
    }

    return {
      countries,
      brands,
      sizes
    }
  },
  data () {
    const head = {
      title: 'Runnea - Auth Signin',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    const signinData = {
      email: {
        value: '',
        validate: () => this.isEmail()
      },
      password: {
        value: '',
        validate: () => this.isPassword()
      },
      name: {
        value: '',
        validate: () => this.isRequired('name')
      },
      surname: {
        value: '',
        validate: () => this.isRequired('surname')
      },
      sex: {
        value: '',
        validate: () => this.isRequired('sex')
      },
      country: {
        value: projectData.country,
        validate: () => this.isRequired('country')
      },
      postalCode: {
        value: '',
        validate: () => this.isPostalCode()
      },
      brand: {
        value: '',
        validate: () => this.isRequired('brand')
      },
      size: {
        value: '',
        validate: () => this.isRequired('size')
      },
      privacy: {
        value: false,
        validate: () => this.isRequired('privacy', false)
      },
      source: {
        value: 'gembira'
      }
    }

    return {
      projectData,
      formValidated: false,
      showPassword: false,
      signinData,
      feedback: {
        valid: '',
        invalid: ''
      },
      sexos: [
        { value: '', text: this.$t('user_page.select'), disabled: true },
        { value: 'man', text: this.$t('register.man') },
        { value: 'woman', text: this.$t('register.woman') }
      ],
      authId: this.$route.query._ai,
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    fieldState (field) {
      return !this.formValidated ? null : (!this.signinData[field]?.validate || this.signinData[field].validate())
    },
    validateForm () {
      return formFunctions.validate(this.signinData)
    },
    isRequired (field, value = '') {
      return (this.signinData[field].value !== value)
    },
    isEmail () {
      return this.isRequired('email') && formFunctions.isValidEmail(this.signinData.email.value)
    },
    isPassword () {
      return this.isRequired('password') && formFunctions.isValidPassword(this.signinData.password.value)
    },
    isPostalCode () {
      return (this.signinData.country.value !== 'ES') || (this.isRequired('postalCode') && formFunctions.isValidPostalCode(this.signinData.postalCode.value))
    },
    loginWithGoogle () {
      this.$auth.loginWith('google', {
        params: {
          state: this.authId || ''
        }
      })
    },
    async register () {
      this.formValidated = true
      if (this.validateForm()) {
        const bodyFormData = new FormData()
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('email', this.signinData.email.value)
        bodyFormData.append('password', this.signinData.password.value)
        bodyFormData.append('name', this.signinData.name.value + ' ' + this.signinData.surname.value)
        bodyFormData.append('sex', this.signinData.sex.value)
        bodyFormData.append('country', this.signinData.country.value)
        bodyFormData.append('postal_code', this.signinData.postalCode.value)
        bodyFormData.append('brand', this.signinData.brand.value)
        bodyFormData.append('size', this.signinData.size.value)
        bodyFormData.append('source', this.signinData.source.value)
        bodyFormData.append('trigger', getAuthSource(this.authId))
        try {
          await this.$axios.post(this.$config.api + '/app/v1/users/signin', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'No Auth'
            }
          }).then((resp) => {
            if (resp.status === 200) {
              this.feedback.valid = this.$t('register.successfully_registered')
              this.setGTMEvent('sign_up', { trigger: getAuthSource(this.authId), from: this.prevRoute.fullPath })
              this.loginUser()
            } else {
              this.feedback.invalid = resp.statusText
            }
            switch (resp.status) {
              case 491: this.feedback.invalid = this.$t('register.user_not_found'); break
              case 490: this.feedback.invalid = this.$t('register.error_recovering'); break
              case 481: this.feedback.invalid = this.$t('register.email_exists'); break
              case 482: this.feedback.invalid = this.$t('register.email_not_found'); break
            }
          })
        } catch (error) {
          this.feedback.invalid = this.$t('register.not_exist_email')
        }
      }
    },
    loginUser () {
      try {
        const bodyFormData = new FormData()
        bodyFormData.append('_p', this.$config.id)
        bodyFormData.append('email', this.signinData.email.value)
        bodyFormData.append('password', this.signinData.password.value)
        this.$auth.loginWith('local', { data: bodyFormData }).then((resp) => {
          if (resp.status === 200) {
            setAuthNewsletter(this.authId, true)
            this.$router.push({
              path: '/auth/redirect',
              query: {
                _ai: this.authId
              }
            })
          }
        })
      } catch (err) {}
    }
  }
}
