//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import project from '~/static/config/project.json'
import global from '~/mixins/global.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'gallery.amp' : 'gallery'
  },
  async asyncData ({ params, store, $md5, app, error, $axios, $config, route }) {
    // Get JSON data
    const query = `${$config.static}/json/galleries/${params.pathMatch}.json`
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadListsError', query)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
    }
    const galleryPage = store.state.lists[code]
    const pagePath = `/${route.path.split('/')[1]}/`
    // Metas & Head
    const head = {
      title: `${galleryPage.metas.title}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${galleryPage.metas.title}`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${galleryPage.metas.description}`
        },
        {
          property: 'og:url',
          content: `${$config.domain}${route.path}`
        },
        {
          property: 'og:title',
          content: `${galleryPage.metas.title}`
        },
        {
          property: 'og:description',
          content: `${galleryPage.metas.description}`
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: `${$config.locale}`
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, follow'
      })
    }

    galleryPage.content.button.type = 'outside'
    const localDomainExp = new RegExp(`^https?://${project.domains.www.domain}`)
    if (galleryPage.content.button.url && (!galleryPage.content.button.url.match(/^https?:\/\//) || galleryPage.content.button.url.match(localDomainExp))) {
      galleryPage.content.button.type = 'inside'
      galleryPage.content.button.url = galleryPage.content.button.url.replace(localDomainExp, '')
    }
    if ((galleryPage.content.button.type === 'outside') && galleryPage.content.button.track) {
      galleryPage.content.button.target = '_blank'
      galleryPage.content.button.url = `/pagina_galeria/${galleryPage.content.button.shop || 'vacia'}/${project.contents.redirects.all.paths.home}/?cod=${galleryPage.content.button.track}&org=${encodeURIComponent('Articulo galeria')}`
    }
    galleryPage.content.button.nofollow = (galleryPage.content.button.nofollow === '1') && 'nofollow'

    galleryPage.content.modules = {
      prices: false
    }

    if (galleryPage.content.button.product && galleryPage.content.button.product.type && galleryPage.content.button.product.id) {
      let filterStore = ''
      if (galleryPage.content.button.product.store) {
        const filter = store.state.stores.filter((b) => {
          return b.id_contenido === galleryPage.content.button.product.store
        })
        filterStore = (filter.length > 0) ? `&filters=/store-${filter[0].id}` : ''
      }
      const modulePrices = `${$config.api}/app/v1/prices/list?_p=${$config.id}&products_type=${galleryPage.content.button.product.type}${filterStore}&prices=1&products=${galleryPage.content.button.product.id}&contents=8`
      const modulePricesId = $md5(modulePrices)
      if (
        typeof store.state.modules[modulePricesId] === 'undefined' ||
        Object.keys(store.state.modules[modulePricesId]).length === 0
      ) {
        await store.dispatch('loadModules', modulePrices)
      }
      const modulePricesContent = store.state.modules[modulePricesId]

      if (modulePricesContent) {
        galleryPage.content.modules.prices = {
          template: 'ModulePrices',
          type: 'PriceCard',
          content: {
            info: modulePricesContent.contents
          },
          elements: {
            brand: true,
            name: false,
            photo: true,
            price: true,
            sizes: true,
            logo: true,
            shipping: true
          },
          generic: {
            header: {
              text: app.i18n.t('product_page.featured_prices')
            }
          }
        }
      }

      const productData = await $axios.get(`${$config.static}/json/data/${galleryPage.content.button.product.type}/${galleryPage.content.button.product.id}.json`)
      if (productData && productData.data && productData.data.price && productData.data.price.real && productData.data.price.lower) {
        galleryPage.content.button.pvp = productData.data.price.real
        galleryPage.content.button.price = productData.data.price.lower
      }
    }

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'gallery_page')
    }

    return {
      galleryPage,
      head,
      pagePath
    }
  },
  data () {
    return {
      project,
      logo: {
        src: require('@/assets/img/logo-header-runnea.svg'),
        alt: 'Logo runnea',
        width: 140,
        height: 25
      }
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
