//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  methods: {
    goTo (url) {
      this.$router.push(url)
    },
    copiarTexto (id, str, shareTxt, copiedTxt) {
      setTimeout(() => {
        document.getElementById('btn-copy-' + id).innerHTML = shareTxt
      }, 2000)
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        document.getElementById('btn-copy-' + id).innerHTML = '¡' + copiedTxt + '!'
        return navigator.clipboard.writeText('https://' + window.location.host + str)
      } else {
        return false
      }
    },
    setDiscountDesc (desc) {
      if (desc.length > 100) {
        return desc.substring(0, 100) + '... '
      } else {
        return desc
      }
    },
    setDate (date) {
      const dateYear = date.substring(0, 4)
      const dateMonth = date.substring(4, 6)
      const dateDay = date.substring(6)

      return this.$t('DiscountCodeCard.date_up_to') + ' ' + dateDay + '/' + dateMonth + '/' + dateYear
    }
  }
}
