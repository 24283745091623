//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    avatar: {
      type: [Object, Array, String],
      default: () => [{}, [], '']
    },
    user: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    question: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    description2: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      plus: { alt: 'close', src: require('@/assets/img/icons/icon-plus.svg'), width: 11, heigth: 11 },
      minus: { alt: 'open', src: require('@/assets/img/icons/icon-minus-primary.svg'), width: 11, heigth: 11 }
    }
  }
}
