//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StoreLeaf',
  props: {
    tienda : {
      type: [Object, Array],
      default: () => [{}, []]
    },
  }
}
