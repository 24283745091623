
export function filtersUrlIsIndexable (filters, productType, where) {
  let index = true

  if (filters && productType?.filters) {
    let numActiveFilters = 0
    let indexLevelProductType = (productType.index_level && productType.index_level[where]) || 2
    if (productType.index_level && (productType.index_level[where] === 0)) {
      indexLevelProductType = 0
    }
    let indexLevelFilter = 0
    let indexLevelValue = 0
    Object.keys(filters).forEach((filterId) => {
      if (filters[filterId].length > 0) {
        const productTypeFilter = productType.filters && productType.filters.find((pdFilter) => {
          return filterId === pdFilter.id
        })
        if (productTypeFilter?.index_level && productTypeFilter.index_level[where] && (!indexLevelFilter || (productTypeFilter.index_level[where] < indexLevelFilter))) {
          indexLevelFilter = productTypeFilter.index_level[where]
        }

        let numActiveValues = 0
        filters[filterId].forEach((value) => {
          numActiveValues++

          if (productTypeFilter) {
            const productTypeValue = productTypeFilter.values && productTypeFilter.values.find((pdValue) => {
              return value === pdValue.id
            })
            if (productTypeValue?.index_level && productTypeValue.index_level[where] && (!indexLevelValue || (productTypeValue.index_level[where] < indexLevelValue))) {
              indexLevelValue = productTypeValue.index_level[where]
            }
          }
        })

        if (numActiveValues > 1) {
          index = false
          return false
        }

        numActiveFilters++
      }
    })

    let indexLevel = indexLevelValue || indexLevelFilter || indexLevelProductType
    if ((indexLevelValue < -1) || (indexLevelFilter < -1) || (indexLevelProductType < -1)) {
      indexLevel = -1
    }

    if (numActiveFilters > indexLevel) {
      index = false
      return false
    }
  }

  return index
}
