export default function ({ $axios, $config, store, redirect, app }) {
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 403) {
      try {
        const bodyFormData = new FormData()
        bodyFormData.append('refresh_token', app.$auth.strategy.refreshToken.get())
        bodyFormData.append('access_token', app.$auth.strategy.token.get())
        bodyFormData.append('_p', $config.id)
        $axios.post($config.api + '/app/v1/tokens/refresh', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'No Auth'
          }
        }).then((resp) => {
          app.$auth.$storage.setState('loggedIn', true)
          app.$auth.setToken('local', 'Bearer ' + resp.data.access_token)
          $axios.get($config.api + '/app/v1/users/verify', {
            headers: {
              Authorization: 'Bearer ' + resp.data.access_token
            }
          }).then((user) => {
            app.$auth.setUser(user.data)
          })
        })
      } catch (error) {
        // deslogueamos
        app.$auth.logout()
      }
    } else if (code === 497 || code === 498 || code === 499) {
      app.$auth.logout()
    } else if (code === 480 || code === 481 || code === 491) {
      return error.response
    }
  })
}
