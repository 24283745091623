var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAMP)?_c('div',{staticClass:"PgePrdLst amp-container bg-white"},[_c('div',[(_vm.zones && _vm.zones.GlobalData.zones.ABC_0)?_c('AppDynamicComponent',{attrs:{"items":_vm.zones.GlobalData.zones.ABC_0}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"list"},[_c('h1',[_vm._v(_vm._s(_vm.header.h1))]),_vm._v(" "),_c('span',{staticClass:"showing"},[_vm._v("\n        "+_vm._s(_vm.$t('product_list.show'))+"\n        "),_c('strong',[_vm._v("\n          "+_vm._s(_vm.productList.pagination.contents)+" "+_vm._s(_vm.productType.name)+"\n          "+_vm._s(_vm.querySearch)+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t('product_list.of'))+" "+_vm._s(_vm.productList.total)+"\n      ")]),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('b-card-group',{staticClass:"grid",attrs:{"deck":""}},_vm._l((_vm.productList.contents),function(product,index){return _c('ProductCard',{key:product.content_id,attrs:{"product":product,"pos":index,"elements":{
              review: true,
            }}})}),1)],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('ListPagination',{attrs:{"current-page":_vm.productList.pagination.page,"total-items":_vm.productList.total,"items-per-page":_vm.productList.pagination.contents,"follow":_vm.urlIndexable}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[(_vm.articleList && _vm.articleList.contents && _vm.articleList.contents.length > 0)?_c('b-card-group',{staticClass:"justify-content-between card-article-wrapper flex-column",attrs:{"deck":""}},[_vm._l((_vm.articleList.contents),function(article,index){return [(index <= 4)?_c('ArticleCard',{key:index,attrs:{"article":article,"elements":_vm.articleElements}}):_vm._e()]}),_vm._v(" "),_c('a',{staticClass:"with-arrow",on:{"click":_vm.articleSearch}},[_vm._v("\n            "+_vm._s(_vm.$t('product_list.show_more'))+"\n          ")])],2):_vm._e()],1)],1)],1)]):_c('div',{staticClass:"PgePrdLst"},[_c('b-container',[(_vm.zones && _vm.zones.GlobalData.zones.ABC_0)?_c('AppDynamicComponent',{attrs:{"items":_vm.zones.GlobalData.zones.ABC_0}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-content clearfix"},[_c('div',{staticClass:"list"},[_c('h1',{staticClass:"text-left text-lg-center"},[_vm._v("\n          "+_vm._s(_vm.header.h1)+"\n        ")]),_vm._v(" "),_c('b-row',{staticClass:"d-flex d-lg-block"},[_c('b-col',{staticClass:"float-lg-right",attrs:{"lg":"9"}},[(_vm.zones && _vm.zones.GlobalData.zones.ABC_1)?_c('AppDynamicComponent',{attrs:{"items":_vm.zones.GlobalData.zones.ABC_1}}):_vm._e(),_vm._v(" "),(_vm.extraModulesTop)?_c('div',_vm._l((_vm.extraModulesTop),function(item,ind){return _c(item.template,{key:ind,tag:"component",staticClass:"pt-2",attrs:{"data":item.data,"elements":item.elements,"generic":item.generic,"type":item.type,"preload":item.preload}})}),1):_vm._e()],1),_vm._v(" "),_c('b-col',{staticClass:"float-lg-left order-last",attrs:{"lg":"3"}},[(
                _vm.productList &&
                  _vm.productList.contents &&
                  _vm.productList.contents.length > 0 &&
                  _vm.productList.total > 0
              )?_c('ListFilters',{attrs:{"type":"products","filters":_vm.productList.filters,"config":_vm.productType,"text":_vm.querySearch,"origin":'product',"total":_vm.productList.total,"show-small-btn":false}}):_vm._e(),_vm._v(" "),(_vm.zones && _vm.zones.GlobalData && _vm.zones.GlobalData.zones.C_1)?_c('AppDynamicComponent',{staticClass:"sidebar d-lg-block mt-5",attrs:{"items":_vm.zones.GlobalData.zones.C_1,"zone":'C_1'}}):_vm._e()],1),_vm._v(" "),_c('b-col',{staticClass:"float-lg-right",attrs:{"lg":"9"}},[(
                _vm.productList &&
                  _vm.productList.contents &&
                  _vm.productList.contents.length > 0 &&
                  _vm.productList.total > 0
              )?[_c('div',{staticClass:"total-shown"},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.productList.results)+"\n                  "),(
                      _vm.productList.pagination.contents * _vm.productList.pagination.page <=
                        _vm.productList.total
                    )?[_vm._v("\n                    "+_vm._s(_vm.$t('product_list.showing_from'))+"\n                    "+_vm._s(_vm.productList.pagination.contents *
                        (_vm.productList.pagination.page - 1) +
                        1)+"\n                    "+_vm._s(_vm.$t('product_list.to'))+"\n                    "+_vm._s(_vm.productList.pagination.contents * _vm.productList.pagination.page)+")\n                  ")]:_vm._e(),_vm._v(" "),(
                      _vm.productList.pagination.contents * _vm.productList.pagination.page >
                        _vm.productList.total
                    )?[_vm._v("\n                    "+_vm._s(_vm.$t('product_list.showing_from'))+"\n                    "+_vm._s(_vm.productList.pagination.contents *
                        (_vm.productList.pagination.page - 1) +
                        1)+"\n                    "+_vm._s(_vm.$t('product_list.to'))+" "+_vm._s(_vm.productList.total)+")\n                  ")]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"sort"},[_c('b-input-group',[_c('b-input-group-prepend',[_c('span',[_vm._v(_vm._s(_vm.$t('product_list.order_by')))])]),_vm._v(" "),_c('b-form-select',{attrs:{"id":"sort-by","options":_vm.sorting,"required":""},on:{"change":function($event){return _vm.changeOrder(_vm.order)}},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})],1)],1)]),_vm._v(" "),(_vm.filters)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex flex-wrap",attrs:{"lg":"12"}},[(
                      _vm.productList &&
                        _vm.productList.contents &&
                        _vm.productList.contents.length > 0 &&
                        _vm.productList.total > 0
                    )?_c('ListFilters',{attrs:{"type":"products","filters":_vm.productList.filters,"config":_vm.productType,"text":_vm.querySearch,"origin":'product',"total":_vm.productList.total,"show-small-btn":true}}):_vm._e(),_vm._v(" "),_vm._l((_vm.filters),function(filter){return [_vm._l((filter.values),function(data){return [(data.active === 1 && data.finalUrl)?[(data.nofollow)?_c('span',{key:data.id,staticClass:"enlaceofuscadoPills active",on:{"click":function($event){_vm.storeFilters(
                              filter.id,
                              data.id,
                              _vm.productList.filters.url,
                              _vm.productType,
                              'products',
                              '',
                              data.active,
                              _vm.productList.filters.highlight &&
                                _vm.productList.filters.highlight.includes(filter.id)
                                ? true
                                : false,
                              false,
                              _vm.productList.filters,
                              _vm.$route.query
                            ),
                            function (event) { return event.preventDefault(); }}}},[(data && data.name && data.name != '' && filter.id)?[_vm._v("\n                            "+_vm._s(data.name)+"\n                          ")]:_vm._e(),_vm._v(" "),(!data.name && filter.id)?[_vm._v("\n                            "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.productType, true))+"\n                          ")]:_vm._e()],2):_c('nuxt-link',{key:data.id,staticClass:"enlaceofuscadoPills active",style:(filter.id === 'colour' && data.id === 'black'
                              ? ("background:" + (data.id) + "color:white")
                              : filter.id === 'colour' && data.id === 'white'
                                ? ("background:" + (data.id) + "color:black")
                                : filter.id === 'colour'
                                  ? ("background:" + (data.id))
                                  : ''),attrs:{"to":data.finalUrl},nativeOn:{"click":function($event){_vm.storeFilters(
                              filter.id,
                              data.id,
                              _vm.productList.filters.url,
                              _vm.productType,
                              'products',
                              '',
                              data.active,
                              _vm.productList.filters.highlight &&
                                _vm.productList.filters.highlight.includes(filter.id)
                                ? true
                                : false,
                              false,
                              _vm.productList.filters,
                              _vm.$route.query
                            ),
                            function (event) { return event.preventDefault(); }}}},[_vm._v("\n                          "+_vm._s(_vm.getFilterValSeo(filter.id, data.id, _vm.productType, true))+"\n                        ")])]:_vm._e()]})]})],2)],1):_vm._e(),_vm._v(" "),_c('b-card-group',{staticClass:"grid py-4",class:_vm.querySearch !== '' ? 'searchResult' : '',attrs:{"deck":""}},_vm._l((_vm.productList.contents),function(product,index){return _c('ProductCard',{key:product.content_id,attrs:{"product":product,"gender":_vm.filters.some(function (p) { return p.id === 'gender'; })
                      ? _vm.filters[
                        _vm.filters.findIndex(function (p) { return p.id === 'gender'; })
                      ].values.map(function (p) { return (p.active > 0 ? p.id : null); })
                      : null,"elements":{
                    review: true,
                    compare: true,
                    score: _vm.querySearch !== '' ? false : true,
                    discount: _vm.querySearch !== '' ? false : true,
                    price: _vm.querySearch !== '' ? false : true,
                    arrow: _vm.querySearch !== '' ? true : false,
                  },"product-type":_vm.productType.texts && _vm.productType.texts.name_s,"w":300,"preload":index <= 1,"pos":index}})}),1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('ListPagination',{attrs:{"current-page":_vm.productList.pagination.page,"total-items":_vm.productList.total,"items-per-page":_vm.productList.pagination.contents,"follow":_vm.urlIndexable}})],1),_vm._v(" "),(_vm.extraModulesBottom && _vm.extraModulesBottom.length > 0)?_c('div',{staticClass:"py-4",attrs:{"id":"marca-2-usuarios"}},_vm._l((_vm.extraModulesBottom),function(item,ind){return _c(item.template,{key:ind,tag:"component",staticClass:"pt-2",attrs:{"data":item.data,"elements":item.elements,"generic":item.generic,"type":item.type}})}),1):_vm._e(),_vm._v(" "),(_vm.brand !== null)?_c('div',[_c('BrandCardLite',{attrs:{"brand":_vm.brand,"links":false}})],1):_vm._e(),_vm._v(" "),(_vm.zones && _vm.zones.GlobalData.zones.ABC_2)?_c('AppDynamicComponent',{staticClass:"px-0 pb-5",attrs:{"items":_vm.zones.GlobalData.zones.ABC_2}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.articleList.contents && _vm.articleList.contents.length > 0)?_c('b-card-group',{staticClass:"justify-content-between card-article-wrapper flex-column",attrs:{"deck":""}},[(_vm.productList && _vm.productList.contents && _vm.productList.contents.length > 0)?_c('h2',[_vm._v("\n                "+_vm._s(((_vm.$t('product_list.search_article_title')) + " '" + _vm.querySearch + "'"))+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.articleList.contents),function(article,index){return [_c('ArticleCard',{key:index,attrs:{"article":article,"elements":{
                    title: true,
                    photo: true
                  }}})]}),_vm._v(" "),_c('a',{staticClass:"link-in with-arrow",on:{"click":_vm.articleSearch}},[_vm._v("\n                "+_vm._s(_vm.$t('product_list.show_more'))+"\n              ")])],2):(((!_vm.articleList.contents || _vm.articleList.contents.length === 0) && (!_vm.productList.contents || _vm.productList.contents.length === 0)))?[_c('div',{staticClass:"text-left"},[_c('PriceNotFound',{attrs:{"type":_vm.productType.name,"pid":_vm.productType.paths.home,"path":((_vm.productType.paths.products) + "/" + (_vm.productType.paths.products_all)),"search":_vm.querySearch}})],1)]:_vm._e(),_vm._v(" "),(_vm.productList.total === 0)?_c('div',[(_vm.zones && _vm.zones.GlobalData.zones.SIN_RESULTADOS)?_c('AppDynamicComponent',{attrs:{"items":_vm.zones.GlobalData.zones.SIN_RESULTADOS}}):_vm._e()],1):_vm._e()],2)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }