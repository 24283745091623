//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModuleProductSimilar',
  components: {
    ProductCard: () => import('~/components/ProductCard.vue'),
    ProductCardPrincipal: () => import('~/components/ProductCardPrincipal.vue'),
    ProductCardSecondary: () => import('~/components/ProductCardSecondary.vue'),
    ProductCardStandard: () => import('~/components/ProductCardStandard.vue'),
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null
    },
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    generic: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    type: {
      type: String,
      default: ''
    },
    org:{
      type: String,
      default: ''
    },
    preload: {
      type: Boolean,
      default: false
    }
  }
}
