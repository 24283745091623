//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { customPageView } from '~/lib/gtm'

export default {
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  layout: 'redirecting',
  data () {
    const head = {
      title: 'Runnea - Redirect'
    }
    return {
      url: '',
      runnealogo: { width: 143, height: 25, alt: 'Runnea logo', class: 'runnea-logo', src: require('@/assets/img/logo-header-runnea.svg') },
      prevRoute: null,
      head,
      tracking: '',
      timer: 0
    }
  },
  head () {
    return this.head
  },
  async mounted () {
    customPageView.apply(this, [this.head.title], false)

    const gtOrigen = this.$cookiz.get('gt_origen')
    const gtGclid = this.$cookiz.get('gt_gclid')

    const apiBaseUrl = this.$config.api + '/app/v1/prices/tracking_url'

    let apiQryStr = `_p=${this.$config.id}&url=` + encodeURIComponent(this.$route.query.url)
    apiQryStr += (this.$route.query.pos) ? '&pos=' + encodeURIComponent(this.$route.query.pos) : ''
    apiQryStr += (this.$route.query.org) ? '&org=' + encodeURIComponent(this.$route.query.org) : ''
    apiQryStr += (this.$route.query.at) ? '&at=' + encodeURIComponent(this.$route.query.at) : ''
    apiQryStr += this.prevRoute ? '&url_origen=' + encodeURIComponent(this.prevRoute.path) : ''
    apiQryStr += gtOrigen ? '&source=' + gtOrigen : ''
    apiQryStr += gtGclid ? '&gclid=' + gtGclid : ''

    // User Agent
    const d = this.$device

    let so = 'unknown'
    if (d.isAndroid) { so = 'android' } else if (d.isIos) { so = 'ios' } else if (d.isWindows) { so = 'windows' } else if (d.isMacOS) { so = 'macos' }
    let browser = 'unknown'
    if (d.isChrome) { browser = 'chrome' } else if (d.isSafari) { browser = 'safari' } else if (d.isEdge) { browser = 'edge' } else if (d.isFirefox) { browser = 'firefox' } else if (d.isCrawler) { browser = 'crawler' }
    let device = 'unknown'
    if (d.isMobile) { device = 'mobile' } else if (d.isDesktop) { device = 'desktop' } else if (d.isTablet) { device = 'tablet' }
    apiQryStr += `&so=${so}&browser=${browser}&device=${device}`

    this.tracking = await this.$axios.get(apiBaseUrl + '?' + apiQryStr, { withCredentials: true })

    setTimeout(() => this.activeRedirect(), 1500)
  },
  methods: {
    activeRedirect () {
      window.location.replace(this.tracking.data.url)
    }
  }
}
