//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAuthNewsletter, applyAuthLocation, authData } from '~/lib/auth'

export default {
  layout: 'clean',
  data () {
    return {
      runnealogo: { width: 143, height: 25, alt: 'Runnea logo', class: 'runnea-logo mb-4', src: require('@/assets/img/logo-header-runnea.svg') },
      authId: this.$route.query._ai
    }
  },
  mounted () {
    if (!authData(this.$auth.user)) {
      this.$router.push({
        path: '/auth/data',
        query: {
          _ai: this.authId
        }
      })
    } else if (getAuthNewsletter(this.authId)) {
      this.$router.push({
        path: '/auth/newsletter',
        query: {
          _ai: this.authId
        }
      })
    } else {
      window.location.href = applyAuthLocation.apply(this)
    }
  }
}
