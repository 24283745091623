//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as murmurhash from 'murmurhash'
import global from '~/mixins/global.js'
export default {
  name: 'ModuleChats',
  ...(process.env.cache && { serverCacheKey: props => murmurhash.v2(JSON.stringify(props)) }),
  mixins: [global],
  props: {
    item: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    comments: {
      type: Array,
      default: () => []
    },
    viewAllButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      title: `${this.item?.brand?.name ?? ''} ${this.item?.name ?? (this.item?.title ?? '')}`,
      totalChats: (parseInt(this.item?.chats?.comments?.total) + parseInt(this.item?.chats?.questions?.total))
    }
  },
  methods: {
    getShownQuestions (total) {
      if (total >= 5) { return 5 } else { return total }
    }
  }
}
