//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'clean',
  data () {
    return {
      runnealogo: { width: 143, height: 25, alt: 'Runnea logo', class: 'runnea-logo mb-4', src: require('@/assets/img/logo-header-runnea.svg') }
    }
  },
  mounted () {
    const path = this.$route.query.path || '/'
    delete this.$route.query.path
    this.$store.dispatch('countries/setCountry', this.$route.params.country && parseInt(this.$route.params.country))
    this.$router.push({
      path,
      query: this.$route.query,
      hash: this.$route.hash
    })
  }
}
