//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const FACTOR = 5
const MAX_INDICES = 10
const MAX_CERCANOS = 2
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalItems: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    isTopPagination: {
      type: Boolean,
      default: true
    },
    follow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const pathMatches = this.$route.fullPath.match(/^(.+?\/)([0-9]+\/?)?(\?.*)?(#.*)?$/)

    const path = pathMatches?.[1] ?? []
    return {
      showServer: true,
      showMoreServer: true,
      pagina: this.currentPage,
      paginaActual: this.pagina,
      path
    }
  },
  computed: {
    cuantos () {
      return this.totalItems || 0
    },
    porPagina () {
      return this.itemsPerPage || 1
    },
    paginas () {
      return Math.ceil(this.cuantos / this.porPagina)
    },
    showMore () {
      return this.paginas > this.paginaActual
    },
    indices () {
      const indices = []

      let actual = this.pagina
      let primero = 0
      for (let i = this.pagina - MAX_CERCANOS; i < this.pagina; i += 1) {
        if (i > 0) {
          indices.push(i)
          if (primero === 0) {
            primero = i
          }
        }
      }

      indices.push(this.pagina)
      for (let i = this.pagina + 1; i <= (this.pagina + MAX_CERCANOS); i += 1) {
        if (i <= this.paginas) {
          indices.push(i)
          actual = i
        }
      }
      if (actual < this.paginas) {
        for (let i = (actual + 1); i <= this.paginas; i += 1) {
          if (i % FACTOR === 0) {
            indices.push(i)

            if (indices.length === MAX_INDICES) {
              break
            }
          }
        }
      }
      if (indices.length < MAX_INDICES) {
        for (let i = primero; i >= 1; i -= 1) {
          if (i % FACTOR === 0) {
            indices.unshift(i)

            if (indices.length === MAX_INDICES) {
              break
            }
          }
        }
      }
      return indices
    }
  },
  mounted () {
    this.showServer = false
    this.showMoreServer = false
  },
  methods: {
    path_to (path, page, queryString) {
      const pathTo = {
        path
      }
      if (queryString) {
        pathTo.query = queryString
      }
      if (page && (page > 1)) {
        pathTo.path += page + '/'
      }
      return pathTo
    },
    cargarMas () {
      this.paginaActual += 1
      this.$emit('cargarMas', this.paginaActual)
    },
    ofuscatedLink (encodedUrl) {
      this.$router.push(encodedUrl)
    }
  }
}
