//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  components: {
    // ProductCard: () => import('~/components/ProductCardStandard.vue')
  },
  mixins: [global],
  props: {
    review: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      avatar: {
        src:
          this.review.user?.avatar?.src ?? require('@/assets/img/icons/icon-avatar.svg'),
        width: this.review.user?.avatar?.width ?? 50,
        height: this.review.user?.avatar?.height ?? 50,
        alt: this.review.user?.name ?? 'avatar opinion'
      }
    }
  },
  methods: {
    media () {
      const total = []
      let suma = 0
      Object.entries(this.review.performances).forEach(([key, val]) => {
        total.push(val.score)
        suma++
      })

      return (
        total.reduce(function (total, num) {
          return total + num
        }, 0) / suma
      ).toFixed(1)
    }
  }
}
