//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import project from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    LazyHydrate
  },
  async asyncData ({ params, route, app, store, error, $axios, $md5, $config }) {
    // Params & props
    const routeProps = route.matched[0].props.default
    const TypeId = (routeProps && routeProps.prodTypeId > 0) ? routeProps.prodTypeId : 0
    const productType = Object.values(project.contents.product_types).find(
      p => p.id === `${TypeId}`
    )
    let author = false

    // Zones & Heritages
    const ax = new AxiApi($config.api)
    let zones
    try {
      zones = await $axios.get((TypeId && `${$config.static}/json/zones/products/${TypeId}/zones.json`) || `${$config.static}/json/zones/products/zones.json`)
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(zones.data, store)

    // Breadcrumb
    const breadcrumb = routeProps.breadcrumb

    // Get API data
    let page = 1
    const filters = []
    const filtersSEO = []
    let filters2api = ''
    let query = ''
    let code = ''
    if (productType) {
      if (parseInt(productType.id) > 0) {
        filters.push({ id: 'product_type', value: productType.id })
      }
      filtersSEO.push((productType.seo || productType.name).toLowerCase())
    }
    if (params.autor && params.autor !== '') {
      filters.push({ id: 'author', value: params.autor })

      const authorQry = $config.static + '/json/authors/' + params.autor + '.json'
      const authorCode = $md5(authorQry)
      if (
        typeof store.state.lists[authorCode] === 'undefined' ||
        Object.keys(store.state.lists[authorCode]).length === 0
      ) {
        await store.dispatch('loadLists', authorQry)
      }
      author = store.state.lists[authorCode]

      project.id === 21
        ? filtersSEO.push(`by ${author.name}`)
        : filtersSEO.push(`de ${author.name}`)
    }
    if (params.pag && params.pag > 0) {
      page = params.pag
    }
    // Formar URL y llamada a API
    filters.forEach((f) => {
      filters2api += `/${f.id}-${f.value}`
    })

    query = `${$config.api}/app/v1/reviews/list?_p=${$config.id}&filters=${filters2api}&page=${page}`
    code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      await store.dispatch('loadLists', query)
    }
    const reviews = store.state.lists[code]

    // Structured Data
    const structuredDataArticle = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      url: '/' + project.contents.reviews.all.paths.home + '/' + project.contents.reviews.all.paths.authors + '/',
      numberOfItems: `${reviews.pagination.contents}`,
      itemListElement: []
    }
    reviews.contents.forEach(function (review, index) {
      const pathMatches = review.product.path.match(/^\/json\/products\/([0-9]+)(.+?)index.json$/)
      const prodTypeConfig = Object.values(project.contents.product_types).find(p => p.id === pathMatches[1])
      const prodUrl = `/${prodTypeConfig.paths.home}${pathMatches[2]}`
      const item = {
        '@type': 'Review',
        position: `${index + 1}`,
        author: `${review.author.name}`,
        name: `${review.title}`,
        datePublished: `${review.date}`,
        reviewRating: {
          '@type': 'Rating',
          bestRating: `${review.score}`,
          ratingValue: `${review.score}`
        },
        url: `${$config.domain}${prodUrl}`,
        itemReviewed: {
          '@type': 'Product',
          name: `${review.product.name}`,
          aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: `${review.score}`,
            ratingValue: `${review.score}`,
            worstRating: `${review.score}`,
            reviewCount: '1'
          }
        }
      }
      structuredDataArticle.itemListElement.push(item)
    })

    const structuredDataBreadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: []
    }
    breadcrumb.forEach(function (element, index) {
      const item = {
        '@type': 'ListItem',
        position: `${index + 1}`,
        item: {
          '@id': `${$config.domain}` + element.href,
          name: element.text
        }
      }
      structuredDataBreadcrumbList.itemListElement.push(item)
    })

    // Metas, Head & Header
    const dinamicSeo = filtersSEO.join(' ')
    const header = {
      h1: project.id === 21
        ? (author ? (app.i18n.t('review_list.page_title').replace('{product}', productType.seo || productType.name) + ` ${app.i18n.t('review_list.by')} ${author.name}`) : app.i18n.t('review_list.page_title').replace('{product}', productType.seo || productType.name))
        : app.i18n.t('review_list.page_title').replace('{seo}', dinamicSeo)
    }
    const head = {
      title: project.id === 21
        ? (author ? (app.i18n.t('review_list.meta_title').replace('{product}', productType.seo || productType.name) + ` ${app.i18n.t('review_list.by')} ${author.name}`) + ' | Runnea' : (app.i18n.t('review_list.meta_title').replace('{product}', productType.seo || productType.name)) + ' | Runnea')
        : app.i18n.t('review_list.meta_title').replace('{seo}', dinamicSeo) + ' | Runnea',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: project.id === 21
            ? (author ? (app.i18n.t('review_list.meta_title').replace('{product}', productType.seo || productType.name) + ` ${app.i18n.t('review_list.by')} ${author.name}`) : (app.i18n.t('review_list.meta_title').replace('{product}', productType.seo || productType.name)))
            : app.i18n.t('review_list.meta_title').replace('{seo}', dinamicSeo)
        },
        {
          hid: 'description',
          name: 'description',
          content: project.id === 21
            ? (author ? (app.i18n.t('review_list.meta_description').replace('{product}', productType.seo || productType.name) + ` ${app.i18n.t('review_list.by')} ${author.name}`) : (app.i18n.t('review_list.meta_description').replace('{product}', productType.seo || productType.name)))
            : app.i18n.t('review_list.meta_description').replace('{seo}', dinamicSeo)
        }
      ],

      script: [
        { type: 'application/ld+json', json: structuredDataArticle },
        { type: 'application/ld+json', json: structuredDataBreadcrumbList }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'review_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      zones: zonesContent,
      reviews,
      author,
      head,
      header
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
