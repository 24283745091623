//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'

export default {
  components: {
    StoreLeaf: () => import('~/components/StoreLeaf.vue')
  },
  async asyncData ({ params, route, error, store, app, $axios, $config, $md5 }) {
    // Zones & Heritages

    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/stores/zones.json'
    const zones = await $axios.get(call)
    const tienda = params.tienda

    // Get data

    const types = {}
    const typesArray = Object.keys(projectData.contents.product_types)
    typesArray.forEach((type) => {
      types[projectData.contents.product_types[type].id] = type
    })

    let tiendaData = null

    const getStoreData = new Promise((resolve, reject) => {
      if (!store.state.storesLoaded) {
        store.dispatch('loadStores').then(() => {
          tiendaData = store.state.stores.find(
            (store) => store.id === params.tienda
          )
        })
      } else {
        tiendaData = store.state.stores.find(
          (store) => store.id === params.tienda
        )
      }
      tiendaData ? resolve(tiendaData) : reject(error('Store not found'))
    })

    try {
      tiendaData = await getStoreData
    } catch (e) {
      return e
    }

    if (tiendaData?.pagina?.chollos && (tiendaData?.pagina?.chollos.length > 0)) {
      tiendaData.pagina.chollos.forEach((chollo) => {
        zones.data.zones.ABC_1.push({
          ConfigurationFile: {
            template: 'ModulePricesBargain',
            type: 'PriceCard',
            elements: {
              brand: '1',
              name: '1',
              photo: '1',
              price: '1',
              sizes: '1',
              logo: '1',
              shipping: '1',
              featured: '1'
            },
            content: {
              info: [],
              api: {
                name: 'prices',
                type: 'list',
                filters: {
                  store: {
                    values: [
                      tiendaData.id
                    ]
                  }
                },
                params: {
                  products_type: chollo.productos_tipo,
                  advertising: '0',
                  type: 'bargain'
                },
                contents: chollo.total,
                orders: {
                  order: chollo.algoritmo,
                  direction: 'desc'
                }
              }
            },
            generic: {
              header: {
                text_module: chollo.titulo
              }
            }
          }
        })
      })
    }

    await ax.createComponentData(zones.data, store)

    const bestProducts = `${$config.api}/app/v1/prices/list?_p=${$config.id}&products_type=1&prices=1&advertising=0&contents=8&filters=store-${tiendaData?.id}&sort=popularity`
    const bestProducts1 = $md5(bestProducts)
    if (
      typeof store.state.modules[bestProducts1] === 'undefined' ||
        Object.keys(store.state.modules[bestProducts1]).length === 0
    ) {
      await store.dispatch('loadModules', bestProducts)
    }
    const bestProductsList = store.state.modules[bestProducts1]?.contents

    // Metas & Head
    const head = {
      title: app.i18n.t('brand_list.title') ? app.i18n.t('brand_list.title') : '',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: app.i18n.t('brand_list.title') ? app.i18n.t('brand_list.title') : ''
        },
        {
          hid: 'description',
          name: 'description',
          content: app.i18n.t('brand_list.description') ? app.i18n.t('brand_list.description') : ''
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'brand_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      bestProductsList,
      head,
      projectData,
      tienda,
      tiendaData,
      types,
      zones: zonesContent
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }

}
