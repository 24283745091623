
import { md5 } from 'pure-md5'

export function setAuthId () {
  const authId = md5(Math.random().toString())
  if (process.client) {
    localStorage.setItem('authId', authId)
  }
  return getAuthId()
}

export function getAuthId () {
  return (process.client && localStorage.getItem('authId')) || ''
}

export function setAuthSource (id, source) {
  if (process.client) {
    localStorage.setItem('authSource', JSON.stringify({
      id,
      source
    }))
  }
  return getAuthSource(id)
}

export function getAuthSource (id) {
  const authSource = process.client && JSON.parse(localStorage.getItem('authSource') || false)
  return authSource && (authSource.id === id) && authSource.source
}

export function delAuthSource () {
  if (process.client) {
    localStorage.removeItem('authSource')
  }
}

export function setAuthNewsletter (id, newsletter) {
  if (process.client) {
    localStorage.setItem('authNewsletter', JSON.stringify({
      id,
      newsletter: (newsletter && true) || false
    }))
  }
  return getAuthNewsletter(id)
}

export function getAuthNewsletter (id) {
  const authNewsletter = process.client && JSON.parse(localStorage.getItem('authNewsletter') || false)
  return authNewsletter && (authNewsletter.id === id) && authNewsletter.newsletter
}

export function delAuthNewsletter () {
  if (process.client) {
    localStorage.removeItem('authNewsletter')
  }
}

export function setAuthScope (id, scope) {
  if (process.client) {
    localStorage.setItem('authScope', JSON.stringify({
      id,
      scope: scope || false
    }))
  }
  return getAuthScope(id)
}

export function getAuthScope (id) {
  const authScope = process.client && JSON.parse(localStorage.getItem('authScope') || false)
  return authScope && (authScope.id === id) && authScope.scope
}

export function delAuthScope () {
  if (process.client) {
    localStorage.removeItem('authScope')
  }
}

export function setAuthLocation (id, location) {
  location = location || {
    path: this.$route.path
  }
  if (!location.path) {
    location.path = this.$route.path
  }
  location.query = {
    _ai: id
  }
  if (process.client) {
    localStorage.setItem('authLocation', JSON.stringify({
      id,
      location
    }))
  }
  return getAuthLocation(id)
}

export function getAuthLocation (id) {
  const authLocation = process.client && JSON.parse(localStorage.getItem('authLocation') || false)
  return authLocation && (authLocation.id === id) && authLocation.location
}

export function delAuthLocation () {
  if (process.client) {
    localStorage.removeItem('authLocation')
  }
}

export function setAuthAction (id, action) {
  if (process.client) {
    localStorage.setItem('authAction', JSON.stringify({
      id,
      action
    }))
  }
  return getAuthAction(id)
}

export function getAuthAction (id) {
  const authAction = process.client && JSON.parse(localStorage.getItem('authAction') || false)
  return authAction && (authAction.id === id) && authAction.action
}

export function delAuthAction () {
  if (process.client) {
    localStorage.removeItem('authAction')
  }
}

export function delAuth () {
  delAuthScope()
  delAuthSource()
  delAuthNewsletter()
  delAuthLocation()
  delAuthAction()
}

export function openAuthRequired (location = null, action = null, type = 'page', source = null, newsletter = false, scope = false) {
  const authId = setAuthId()

  if ((type !== 'component') && this.$auth.loggedIn && !newsletter) {
    if (action?.name) {
      this[action.name].apply(this, action.params || [])
    }
  } else {
    setAuthLocation.apply(this, [authId, location])
    if (action) {
      setAuthAction(authId, action)
    }
    delAuthScope()
    setAuthScope(authId, scope)
    delAuthSource()
    if (source) {
      setAuthSource(authId, source)
    }
    delAuthNewsletter()
    setAuthNewsletter(authId, newsletter)
    if (this.$auth.loggedIn && newsletter) {
      this.$router.push({
        path: '/auth/newsletter',
        query: {
          _ai: authId
        }
      })
    } else if (type === 'modal') {
      this.$bvModal.show('modal-auth-login')
    } else if (type === 'page') {
      this.$router.push({
        path: '/auth/login',
        query: {
          _ai: authId
        }
      })
    }
  }

  return authId
}

export function applyAuthLocation (id = null, url = true) {
  let location = {
    path: '/'
  }

  const authId = id || this.$route.query._ai
  const authLocation = authId && getAuthLocation(authId)
  if (authLocation?.path) {
    location = authLocation
    delAuthLocation(authId)
  }

  return url ? getLocationUrl(location) : location
}

export function getLocationUrl (location) {
  let url = location.path
  if (location.query) {
    const query = new URLSearchParams(location.query)
    url += '?' + query.toString()
  }
  if (location.hash) {
    url += location.hash
  }

  return url
}

export function applyAuthAction (id = null, scope = false) {
  const authId = id || this.$route.query._ai
  const authAction = authId && getAuthAction(authId)
  const authScope = authId && getAuthScope(authId)
  if (authScope === scope) {
    if (authAction?.name && this[authAction.name]) {
      this[authAction.name].apply(this, authAction.params || [])
    }
    delAuthAction(authId)
  }
}

export function authData (data) {
  return data && data.name && data.sex && data.country && ((data.country !== 'ES') || data.postal_code) && data.brand && data.size
}
