import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { RefreshScheme } from '~auth/runtime'
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": false,
  "redirect": {
    "login": "/auth/login",
    "logout": false,
    "home": false,
    "callback": "/auth/callback"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": false,
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new RefreshScheme($auth, {
  "endpoints": {
    "login": {
      "url": "https://api.runnea.co.uk/app/v1/users/login",
      "method": "post"
    },
    "refresh": {
      "url": "https://api.runnea.co.uk/app/v1/tokens/refresh",
      "method": "post"
    },
    "user": {
      "url": "https://api.runnea.co.uk/app/v1/users/data",
      "method": "get"
    },
    "logout": false
  },
  "token": {
    "property": "access_token",
    "maxAge": 86400
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 31536000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "name": "local"
}))

  // token
  $auth.registerStrategy('token', new RefreshScheme($auth, {
  "endpoints": {
    "login": {
      "url": "https://api.runnea.co.uk/app/v1/users/token",
      "method": "get"
    },
    "refresh": {
      "url": "https://api.runnea.co.uk/app/v1/tokens/refresh",
      "method": "post"
    },
    "user": {
      "url": "https://api.runnea.co.uk/app/v1/users/data",
      "method": "get"
    },
    "logout": false
  },
  "token": {
    "property": "access_token"
  },
  "refreshToken": {
    "property": "refresh_token"
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "name": "token"
}))

  // google
  $auth.registerStrategy('google', new Oauth2Scheme($auth, {
  "clientId": "356967443632-tsoi6pd1o9b0tm3cd3f93fcp7cg4u777.apps.googleusercontent.com",
  "codeChallengeMethod": "",
  "responseType": "code",
  "endpoints": {
    "authorization": "https://accounts.google.com/o/oauth2/auth",
    "userInfo": "https://api.runnea.co.uk/app/v1/users/data",
    "token": "https://api.runnea.co.uk/app/v1/users/google",
    "refresh": {
      "url": "https://api.runnea.co.uk/app/v1/tokens/refresh",
      "method": "post"
    }
  },
  "token": {
    "property": "access_token",
    "maxAge": 86400
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 31536000
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "name": "google",
  "scope": [
    "openid",
    "profile",
    "email"
  ]
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
