
let interval = null

export const init = () => {
  if (interval) {
    clearInterval(interval)
  }
}

export const set = (id) => {
  init()

  window.onwheel = () => {
    init()
  }

  window.ontouchstart = () => {
    init()
  }

  interval = setInterval(() => {
    const header = document.querySelector('header')
    const headerHeight = (header && header.offsetHeight) || 0
    const container = document.querySelector(id)
    const containerTop = container && container.getBoundingClientRect().top
    if (containerTop && ((containerTop < (headerHeight - 5)) || (containerTop > (headerHeight + 5)))) {
      window.scrollTo({
        top: containerTop + window.scrollY - headerHeight,
        behavior: 'smooth'
      })
    } else {
      init()
    }
  }, 1000)
}
