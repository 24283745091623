//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted () {
    this.$refs.carousel.addEventListener('scroll', this.updateArrows)
    window.addEventListener('scroll', this.updateArrows)
    this.updateArrows()
  },
  methods: {
    scrollTo (element, scrollPixels, duration) {
      if (element) {
        const scrollPos = element.scrollLeft
        // Condition to check if scrolling is required
        if (
          !(
            (scrollPos === 0 || scrollPixels > 0) &&
            (element.clientWidth + scrollPos === element.scrollWidth ||
              scrollPixels < 0)
          )
        ) {
          // Get the start timestamp
          const startTime =
            'now' in window.performance
              ? performance.now()
              : new Date().getTime()

          const that = this

          function scroll (timestamp) {
            // Calculate the timeelapsed
            const timeElapsed = timestamp - startTime
            // Calculate progress
            const progress = Math.min(timeElapsed / duration, 1)
            // Set the scrolleft
            element.scrollLeft = scrollPos + scrollPixels * progress
            // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
            if (timeElapsed < duration) {
              // Request for animation
              window.requestAnimationFrame(scroll)
            } else {
              that.updateArrows()
            }
          }
          // Call requestAnimationFrame on scroll function first time
          window.requestAnimationFrame(scroll)
        }
      }
    },
    updateArrows () {
      if (!this.$refs.carousel) {
        return
      }
      const element = this.$refs.carousel
      const scrollPos = element.scrollLeft

      const leftArrow = this.$refs.buttonleft
      const rightArrow = this.$refs.buttonright
      leftArrow.style.visibility = 'visible'
      rightArrow.style.visibility = 'visible'

      let allHidden = true
      if (scrollPos === 0) {
        leftArrow.style.visibility = 'hidden'
      } else {
        allHidden = false
      }

      if (element.clientWidth + scrollPos === element.scrollWidth) {
        rightArrow.style.visibility = 'hidden'
      } else {
        allHidden = false
      }

      if (!allHidden) {
        window.removeEventListener('scroll', this.updateArrows)
      }
    },
    scrollLeft () {
      if (!this.$refs.carousel) {
        return
      }
      const carousel = this.$refs.carousel
      const card = carousel.querySelector('.card')
      const width = card.offsetWidth
      // const numberCardsScroll = width === carousel.offsetWidth ? 1 : 2
      const numberCardsScroll = 1
      const margin =
        parseFloat(window.getComputedStyle(card).marginLeft) +
        parseFloat(window.getComputedStyle(card).marginRight)
      this.scrollTo(carousel, (width + margin) * -numberCardsScroll, 500)
    },
    scrollRight () {
      if (!this.$refs.carousel) {
        return
      }
      const carousel = this.$refs.carousel
      const card = carousel.querySelector('.card')
      const width = card.offsetWidth
      // const numberCardsScroll = width === carousel.offsetWidth ? 1 : 2
      const numberCardsScroll = 1

      const margin =
        parseFloat(window.getComputedStyle(card).marginLeft) +
        parseFloat(window.getComputedStyle(card).marginRight)

      this.scrollTo(carousel, (width + margin) * numberCardsScroll, 500)
    }
  }
}
