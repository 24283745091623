//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as cssFunctions from '~/mixins/css.functions.js'
export default {
  async asyncData ({ app, route, $config }) {
    const head = {}

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'user_page')
    }

    return {
      head
    }
  },
  data () {
    return {
      sexos: [
        { value: null, text: `${this.$t('user_page.select')}` },
        { value: '', text: `${this.$t('register.sex')}` },
        { value: 'man', text: `${this.$t('register.man')}` },
        { value: 'woman', text: `${this.$t('register.woman')}` }
      ],
      pisada: [
        { value: null, text: `${this.$t('user_page.select')}` },
        { value: 'tread-1', text: `${this.$t('UserRegisterForm.tread_type')} 1` },
        { value: 'tread-2', text: `${this.$t('UserRegisterForm.tread_type')} 2` }
      ]
    }
  },
  head () {
    return this.head
  }
}
