//
//
//
//
//
//
//

import { customPageView } from '~/lib/gtm'

export default {
  layout: 'clean.close',
  data () {
    const head = {
      title: 'Runnea - Auth Login',
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.domain}${this.$route.path}`
        }
      ]
    }
    return {
      head
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  created () {
    if (this.$auth.loggedIn) {
      this.$router.push('/')
    }
  }
}
