//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import project from '~/static/config/project.json'
export default {
  mixins: [global],
  props: {
    race: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    elements: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      iconData: require('~/assets/img/icons/icon-calendar-primary.svg'),
      iconLocation: require('~/assets/img/icons/icon-location-primary.svg'),
      iconDistance: require('~/assets/img/icons/icon-distance-primary.svg'),
      project
    }
  },
  methods: {
    getProvince (prov) {
      const provinceF = project.contents.races.all.filters.find(f => f.id === 'province')
      const provinceFV = provinceF?.values.find(v => v.id === prov)
      return provinceFV?.name || ''
    }
  }
}
