//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import project from '~/static/config/project.json'
import global from '~/mixins/global.js'
import { customPageView } from '~/lib/gtm'

const config = {
  type: 1,
  tabs: [
    {
      id: 'start',
      step: false
    },
    {
      id: 'purpose',
      step: true
    },
    {
      id: 'gender',
      step: true
    },
    {
      id: 'size',
      step: true
    },
    {
      id: 'footprint',
      step: true,
      results: true
    },
    {
      id: 'constitution',
      step: true,
      results: true
    },
    {
      id: 'cushioning',
      step: true,
      results: true
    },
    {
      id: 'foot',
      step: true,
      results: true
    },
    {
      id: 'price',
      step: true,
      results: true
    },
    {
      id: 'beat',
      step: true,
      results: true
    },
    {
      id: 'brand',
      step: true,
      results: true
    },
    {
      id: 'result',
      step: false,
      action: (app) => {
        app.getResults()
      }
    }
  ]
}

export default {
  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'recommender.amp' : 'recommender'
  },
  async asyncData ({ $axios, store, $config }) {
    const recommender = {
      tab: config.tabs.length && { index: 0, id: config.tabs[0].id, results: config.tabs[0].results },
      steps: [],
      step: config.tabs.length && config.tabs[0].step && { index: 0, id: config.tabs[0].id },
      productsType: null,
      brands: [],
      sizes: [],
      filters: null,
      filtersSelected: {},
      pricesFiltersSelected: {}
    }

    recommender.steps = config.tabs.filter(tab => tab.step)

    recommender.productsType = Object.values(project.contents.product_types).find(
      pt => pt.id === config.type.toString()
    )

    if (recommender.productsType?.filters) {
      recommender.productsType.filters = recommender.productsType.filters.map(filter => {
        if (
          filter.share &&
              project.filters_share &&
              project.filters_share[filter.share]
        ) {
          filter = { ...project.filters_share[filter.share], ...filter }
        }
        return filter
      })
      recommender.productsType.filters.filter(filter => {
        return filter
      })
    }

    const response = await $axios.get(`${$config.api}/app/v1/products/filters?_p=${$config.id}&products_type=${config.type}`)
    recommender.filters = response?.data

    if (recommender.filters.filters?.talla?.order) {
      recommender.sizes = recommender.filters.filters.talla.order
    }

    recommender.brands = store.state.brands.filter(brand => brand.products.find(brand => parseInt(brand.products_type) === config.type)).sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    return {
      recommender
    }
  },
  data () {
    const head = {
      title: this.$t('recommender.seo.title'),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.$t('recommender.seo.title')
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('recommender.seo.description')
        }
      ],
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/intro.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/logo-runnea-finder.svg')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/finalidad.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/genero.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/talla.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require(`@/assets/img/recomendador/pisada_${this.language}.png`)
        },
        {
          rel: 'preload',
          as: 'image',
          href: require(`@/assets/img/recomendador/constitucion_${this.language}.png`)
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/amortiguacion.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require(`@/assets/img/recomendador/tipo-pie_${this.language}.png`)
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/uso.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/distancia.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/ritmo.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/marca.png')
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('@/assets/img/recomendador/presupuesto.png')
        }
      ]
    }
    return {
      result: {
        state: 'loading',
        contents: []
      },
      language: 'es',
      head
    }
  },
  beforeCreate () {
    this.language = project.language
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  },
  methods: {
    goToTab (tabId) {
      const tabIndex = config.tabs.findIndex(tab => tab.id === tabId)
      this.recommender.tab = (tabIndex >= 0)
        ? {
            index: tabIndex,
            id: tabId,
            results: config.tabs[tabIndex].results
          }
        : null

      const stepIndex = this.recommender.steps.findIndex(step => step.id === tabId)
      this.recommender.step = (stepIndex >= 0)
        ? {
            index: stepIndex,
            id: tabId
          }
        : null

      if (config.tabs[tabIndex].action) {
        config.tabs[tabIndex].action(this)
      }
    },
    goToNext () {
      const tabIndex = this.recommender.tab?.index

      if ((tabIndex !== null) && (tabIndex < (config.tabs.length - 1))) {
        this.goToTab(config.tabs[tabIndex + 1].id)
      }
    },
    setFilter (filter, value, prices = false, next = true) {
      if (this.recommender.filtersSelected[filter]) {
        this.recommender.filtersSelected[filter].push(value)
      } else {
        this.recommender.filtersSelected[filter] = [value]
      }
      if (prices) {
        if (this.recommender.pricesFiltersSelected[filter]) {
          this.recommender.pricesFiltersSelected[filter].push(value)
        } else {
          this.recommender.pricesFiltersSelected[filter] = [value]
        }
      }

      if (next) {
        this.goToNext()
      }
    },
    async getResults () {
      this.result.state = 'loading'
      this.result.contents = []

      if (this.recommender.filters && this.recommender.filtersSelected) {
        let apiFilters = ''

        this.recommender.filters.orders.url.forEach(filter => {
          const filterID = this.recommender.filters.filters[filter] && (this.recommender.filters.filters[filter].api || filter)

          if (this.recommender.filtersSelected[filterID]) {
            apiFilters += '/' + filterID + '-' + this.recommender.filtersSelected[filterID].join(',')
          }
        })

        const response = await this.$axios.get(`${this.$config.api}/app/v1/products/list?_p=${this.$config.id}&products_type=${config.type}&filters=${apiFilters}&contents=3`)
        if (response?.data.contents) {
          this.result.contents = response.data.contents
          this.result.state = 'loaded'
        }
      }
    },
    getFiltersUrl (prices = false, array = false) {
      const filtersSelected = prices ? this.recommender.pricesFiltersSelected : this.recommender.filtersSelected
      if (this.recommender.productsType && this.recommender.filters) {
        const urlPaths = []

        this.recommender.filters.orders.url.forEach(filter => {
          const filterID = this.recommender.filters.filters[filter] && (this.recommender.filters.filters[filter].api || filter)
          const filterConfig = filterID && this.recommender.productsType.filters.find(filter => filter.id === filterID)

          let values = []
          if (filterConfig && ((filterID !== 'brand') || !prices)) {
            if (filtersSelected[filterID]) {
              filtersSelected[filterID].forEach(selectedValue => {
                if (filterConfig.values) {
                  const filterValue = filterConfig.values.find(value => value.id === selectedValue)
                  if (filterValue) {
                    values.push(filterValue.url || filterValue.name)
                  }
                } else {
                  values.push(selectedValue)
                }
              })
            } else if (filterConfig.default?.products?.value) {
              values = [filterConfig.default.products.value]
            }

            if (values.length) {
              urlPaths.push(((filterConfig.prefix?.products !== false) ? (filterConfig.url || filterConfig.name) + '-' : '') + values.join(','))
            }
          }
        })

        return array ? urlPaths : urlPaths.join('/')
      }
    },
    getListUrl (filters = true) {
      if (this.recommender.productsType && this.recommender.filters) {
        const urlPaths = [this.recommender.productsType.paths.home, this.recommender.productsType.paths.products].concat(filters ? this.getFiltersUrl(false, true) : [this.recommender.productsType.paths.products_all])

        return '/' + urlPaths.join('/') + '/'
      }
    }
  }
}
